import { JsonPipe, NgForOf, NgIf, NgOptimizedImage } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl,  FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppConfigService } from '@core/appconfig.service';
import { AuthenticationService } from '@core/authentication.service';
import { BrowserService } from '@core/browser.service';
import { UserService } from '@core/user.service';
import { ApiResponse } from '@models/api-response';
import { ApplicationUser } from '@models/application-user';
import { IUser } from '@models/user';
import { AlertBarContainerComponent } from '@shared/alert-bar-container/alert-bar-container.component';
import { ButtonComponent } from '@shared/button/button.component';
import { CompanyCode } from '@shared/enums/company-code';
import {
  PasswordRequirementsText
} from '@shared/enums/password-requirements';
import { FormHelpers } from '@shared/form-helpers';
import { SharedModule } from '@shared/shared.module';
import { copyObject } from '@shared/zb-object-helper/object-helper';
import { ZbpIconComponent } from '@shared/zbp-icon/zbp-icon';
import { zbpIconNames } from '@shared/zbp-icon/zbp-icon-names';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, catchError, map, mergeMap, of,
  throwError, finalize, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { PreloaderModule } from '../../shared/preloader/preloader.module';


/**
 * Used for Setting up New Accounts & Resetting Existing Account Passwords
 */
@Component({
  selector: 'zbp-branded-account-setup-reset',
  templateUrl: './branded-account-setup-reset.component.html',
  styleUrls: ['./branded-account-setup-reset.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf,
    NgForOf,
    JsonPipe,
    NgOptimizedImage,
    SharedModule,
    ZbpIconComponent,
    PreloaderModule,
    AlertBarContainerComponent,
    ButtonComponent,
  ]
})
export class BrandedAccountSetupResetComponent implements OnInit, OnDestroy {
  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService,
    private userService: UserService,
    private toastr: ToastrService,
    public browserService: BrowserService,
    private appConfigService: AppConfigService,
    private cdref: ChangeDetectorRef) {
    this.form = new FormGroup({
      [this.formControlNames.Password]: new FormControl('', FormHelpers.getPasswordValidator(true)),
      [this.formControlNames.ConfirmPassword]: new FormControl('', FormHelpers.getConfirmPasswordValidator()),
    });
  }
  passwordApiMessages: string[];

  formControlNames = {
    ConfirmPassword: 'confirmPassword',
    Password: 'password'
  };

  private subscriptions: Subscription[] = [];
  private incomingUserId: string;
  private token: string;
  showPassword:boolean = false;
  showCurrentPassword:boolean = false;
  showConfirmPassword:boolean = false;
  titleText: string = 'Password Reset';
  isLoading: boolean = true;
  isSaving: boolean = false;
  isTokenInvalid:boolean = false;
  inAccountSetup = false;
  inResetPassword = false;
  formErrors: string[] = [];
  form: FormGroup = new FormGroup({});
  user: IUser;
  loginBrand: CompanyCode = null;
  isHighlights: boolean = false;
  brandTitleText: string = '';
  containerClass: string = '';
  logoSrc: string = '';
  logoAltText: string = '';
  iconNames = zbpIconNames;
  successfulSave: boolean = false;
  formSubmitted: boolean = false;
  newPasswordMeetsRequirements: boolean = false;
  passwordRequirementsList = Object.values(PasswordRequirementsText);

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnInit() {
    this.setupBrandData();
    this.titleService.setTitle(this.brandTitleText);
    this.subscriptions.push(
      this.route.queryParams
        .pipe(
          take(1), // make it so the observable actually completes
          mergeMap((queryParams: Params) => {
            this.inAccountSetup = this.router.url.includes('/account-setup');
            this.inResetPassword = this.router.url.includes('set-password');
            if (queryParams.token && queryParams.userid && !this.userService.userId) {
            // The query parameter from the Api does not actually have the same encoding as in JavaScript despite what
            // the .NET documentation suggests, but we can still use decodeURIComponent.
              this.token = decodeURIComponent(queryParams.token);
              this.incomingUserId = queryParams.userid;
            } else if (this.userService.userId) {
              this.form.addControl('currentPassword', new FormControl(''));
            } else {
              return of(new ApiResponse<IUser>(false, {
                response: null,
                messages: ['Please use a valid reset password link.'],
              }));
            }

            // Account setup, call the API to get the rest of the user data to hydrate form
            if (this.inAccountSetup || this.inResetPassword) {
              this.titleText = this.inAccountSetup ? 'Account Setup' : this.titleText;
              return this.userService.getAccountSetupInfo(this.incomingUserId, this.token, this.inResetPassword)
                .pipe(
                  map((res) => {
                    if (res.success) {
                    // Replaces query parameter token with a password reset one.
                      this.token = res.response.passwordResetToken;
                      return new ApiResponse<IUser>(true, {
                        response: copyObject({
                          ...res.response,
                          userId: this.incomingUserId,
                        }, ApplicationUser),
                        messages: res.messages,
                      });
                    }
                    return new ApiResponse<IUser>(false, { ...res });
                  }),
                  catchError((error: HttpErrorResponse) => {
                    if (error.status === 500) {
                      this.isTokenInvalid = true;
                      console.error('An 500 error occurred:', error.error);
                      return EMPTY;
                    }
                    return throwError(error);
                  }),
                );
            }

            // Is in Password reset mode
            return of(new ApiResponse<IUser>(true, { response: this.userService.user, messages: [] }));
          }),
          finalize(() => {
          // runs regardless of error or success
            this.isLoading = false;
          })
        )
        .subscribe((res: ApiResponse<IUser>) => {
          if (res.success) {
            this.user = res.response;
          } else {
            this.isTokenInvalid = true;
          }
          this.isLoading = false;
        }),
    );
  }

  /**
   * Checks if ConfirmPassword field is valid and if all password requirements have been checked
   * @returns {boolean} Whether save should be enabled
   */
  isSaveEnabled(): boolean {
    const passwordsMatch = this.form.controls[this.formControlNames.ConfirmPassword]?.value
      === this.form.controls[this.formControlNames.Password]?.value;
    return this.form.controls.password.valid && passwordsMatch;
  }

  private setupBrandData() {
    this.loginBrand = this.appConfigService.loginBrand;

    if (this.loginBrand === CompanyCode.HighlightsPortal) {
      this.isHighlights = true;
      this.containerClass = 'hl-portal-account-setup-reset-container';
      this.brandTitleText  = 'Highlights Portal';
      this.logoSrc  = 'assets/img/highlights-logo.svg';
      this.logoAltText  = 'Highlights Portal logo';
    } else {
      this.isHighlights = false;
      this.containerClass = 'zb-portal-account-setup-reset-container';
      this.brandTitleText  = 'My ZB Portal';
      this.logoSrc  = 'assets/zbportal/img/zb-logo.svg';
      this.logoAltText  = 'My ZB Portal logo';
    }
  }

  navigateToLogin()  {
    this.router.navigateByUrl('/login/user');
  }

  navigateToForgotPassword()  {
    this.router.navigateByUrl('/login/forgot-password');
  }

  submitForm(): void {
    this.isSaving = true;
    this.formErrors = [];

    if (this.form.valid) {
      const args: string[] = [];
      const userId = this.incomingUserId ? this.incomingUserId : this.userService.userId;

      if (this.token) {
        args.push(null, this.token);
      } else {
        args.push(this.form.value.currentPassword);
      }

      this.subscriptions.push(
        // Save the password and redirect to login after logging out.
        this.userService.updateUserPassword(userId, this.form.value.password, ...args)
          .subscribe((res: ApiResponse<boolean>) => {
            if (res.success) {
              if (res.messages.length === 0) {
                this.authService.logout();
                this.isSaving = false;
                this.successfulSave = true;
                this.formSubmitted = true;
              } else {
                // API returned errors and need to show the feedback to the user
                this.successfulSave = false;
                this.formSubmitted = false;
                this.form.get(this.formControlNames.Password).setValue(null);
                this.form.get(this.formControlNames.ConfirmPassword).setValue(null);
                this.form.markAsPristine();
                this.form.setErrors(null);
                this.passwordApiMessages = res.messages;

                // Notify parent component that changes were made
                this.cdref.detectChanges();

                if (!res.response) {
                  this.navigateToForgotPassword();
                }
              }
            } else {
              this.toastr.error('An unknown error has occurred attempting to save your new password.');
              this.navigateToForgotPassword();
            }

            this.isSaving = false;
          })
      );
    } else {
      this.formErrors = FormHelpers.getAllFormErrors(this.form);
    }
  }

  /**
   * Used to validate if a form field has not been touched
   * If it has been touched, checks if field is valid
   * @param {string} name - name of form to validate
   * @returns {boolean} Whether form is valid
   */
  isControlValid(name: string): boolean {
    return this.form.controls[name] && (!this.form.controls[name].touched || this.form.controls[name].valid);
  }


  goForgot(): void {
    this.router.navigateByUrl('/forgot-password');
  }

  /**
  * Toggles whether to show password or not.
  */
  toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }

  toggleShowCurrentPassword(): void {
    this.showCurrentPassword = !this.showCurrentPassword;
  }

  toggleShowConfirmPassword(): void {
    this.showConfirmPassword = !this.showConfirmPassword;
  }
}
