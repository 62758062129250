import { Injectable } from '@angular/core';

import { IFacade } from '@core/store/facade';
import { Classroom } from '@models/classroom';
import { IClassroomContext } from '@models/classroom-context';
import { ClassroomIcon } from '@models/classroom-icon';
import { ClassroomPin } from '@models/classroom-pin';
import { SchoolLicense } from '@models/license/school-license';
import { IProduct } from '@models/product';
import { IProductComponent } from '@models/product-component';
import { IProductLine } from '@models/product-line';
import { IProductVariant } from '@models/product-variant';
import { Quest } from '@models/quest';
import { QuestCollection } from '@models/quest-collection';
import { IUser } from '@models/user';
import { IViewerAsset } from '@models/viewer-asset';
import { Action, Store } from '@ngrx/store';
import { GradeType } from '@shared/enums/grade-type';
import { ProductType } from '@shared/enums/product-type';
import { VariantType } from '@shared/enums/variant-type';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LearningState } from './learning.reducer';
import {
  selectClassroom,
  selectClassroomContext,
  selectClassroomIcons,
  selectClassroomIconsUnloaded,
  selectClassroomLicenses, selectClassroomPins, selectClassrooms, selectClassroomSaved,
  selectClassroomStudents,
  selectClassroomsUnloaded,
  selectErrors, selectPinState, selectProductLine,
  selectProductLines,
  selectProductLinesUnloaded,
  selectQuestLicensesFromClassroom,
  selectSchoolLicenses,
  selectSchoolLicensesUnloaded,
  selectStudentQuests,
  selectStudentQuestsFromProductClassroom,
  selectViewerAssets
} from './learning.selectors';

@Injectable({
  providedIn: 'root',
})

export class LearningFacade implements IFacade {
  productLines$: Observable<IProductLine[]>;
  classrooms$: Observable<Classroom[]>;
  classroomIcons$: Observable<ClassroomIcon[]>;
  schoolLicenses$: Observable<SchoolLicense[]>;
  studentQuests$: Observable<QuestCollection[]>;
  viewerAssets$: Observable<IViewerAsset[]>;
  errors$: Observable<string[]>;
  classroomContext$: Observable<IClassroomContext>;
  canRefreshProductLines$: Observable<boolean>;
  canRefreshClassooms$: Observable<boolean>;
  canRefreshClassroomIcons$: Observable<boolean>;
  canRefreshSchoolLicenses$: Observable<boolean>;
  classroomHasBeenSaved$: Observable<boolean>;
  canCheckForRedirect$: Observable<boolean>;
  classroomPins$: Observable<ClassroomPin[]>;
  pinState$: Observable<boolean>;

  constructor(private store: Store<LearningState>) {
    this.productLines$ = this.store.select(selectProductLines);
    this.canRefreshProductLines$ = this.store.select(selectProductLinesUnloaded);
    this.canRefreshClassooms$ = this.store.select(selectClassroomsUnloaded);
    this.canRefreshClassroomIcons$ = this.store.select(selectClassroomIconsUnloaded);
    this.canRefreshSchoolLicenses$ = this.store.select(selectSchoolLicensesUnloaded);
    this.classroomHasBeenSaved$ = this.store.select(selectClassroomSaved);
    this.classrooms$ = this.store.select(selectClassrooms);
    this.classroomIcons$ = this.store.select(selectClassroomIcons);
    this.schoolLicenses$ = this.store.select(selectSchoolLicenses);
    this.classroomContext$ = this.store.select(selectClassroomContext);
    this.studentQuests$ = this.store.select(selectStudentQuests);
    this.viewerAssets$ = this.store.select(selectViewerAssets);
    this.errors$ = this.store.select(selectErrors);
    this.classroomPins$ = this.store.select(selectClassroomPins);
    this.pinState$ = this.store.select(selectPinState);
  }

  dispatch(action: Action): void {
    this.store.dispatch(action);
  }

  selectWithProps(func: Function, ...props: any[]): Observable<any> {
    return this.store.select(func(...props));
  }

  getClassroom(): Observable<Classroom> {
    return this.store.select(selectClassroom);
  }

  getClassroomStudents(): Observable<IUser[]> {
    return this.store.select(selectClassroomStudents);
  }

  getClassroomLicenses(): Observable<SchoolLicense[]> {
    return this.store.select(selectClassroomLicenses);
  }

  getProductLineByKey(productLineKey: string): Observable<IProductLine> {
    return this.selectWithProps(selectProductLine, productLineKey);
  }

  getProductLineVariant(productLineKey: string, variantType: VariantType): Observable<IProductVariant> {
    return this.getProductLineByKey(productLineKey)
      .pipe(
        map(productLine => (productLine ? productLine.variants.find(v => v.variantType === variantType) : null)),
      );
  }

  getProductComponent(productLineKey: string, variantType: VariantType, componentId: string): Observable<IProductComponent> {
    return this.getProductLineVariant(productLineKey, variantType)
      .pipe(
        map((variant) => {
          if (variant) {
            return variant.skus.reduce((component: IProductComponent, sku: IProduct) => {
              if (!component) {
                return sku.components.find(c => c.componentId === componentId);
              }
              return component;
            }, null);
          }
          return null;
        })
      );
  }

  getProductComponentById(productLineKey: string, variantType: VariantType, productId: string): Observable<IProductComponent> {
    return this.getProductLineVariant(productLineKey, variantType)
      .pipe(
        map((variant) => {
          if (variant) {
            return variant.skus.reduce((component: IProductComponent, sku: IProduct): IProductComponent => (!component
              ? sku.components.find(c => c.productId === productId)
              : component),
            null);
          }
          return null;
        })
      );
  }

  getQuestsByProductClassroom(productType: ProductType, classroomId: string, gradeType: GradeType | string): Observable<Quest[]> {
    return this.selectWithProps(selectStudentQuestsFromProductClassroom, productType, classroomId, gradeType);
  }

  getQuestProductsForClassroom(includeRestricted: boolean = false): Observable<SchoolLicense[]> {
    return this.selectWithProps(selectQuestLicensesFromClassroom, includeRestricted);
  }

  getClassroomPins(): Observable<ClassroomPin[]> {
    return this.store.select(selectClassroomPins);
  }
}
