<div class="sso-divider">OR</div>
<div class="sso-button-container">
  <button *ngFor="let provider of ssoProviders"
          id="sso-button-{{provider.idSuffix}}"
          class="sso-button"
          (click)="onSsoButtonClick(provider)"
          [disabled]="isLoadingSsoRedirect"
          [attr.aria-label]="'Sign in with ' + provider.displayName"
      >
    <img [src]="provider.imgPath"
         alt=""
         class="sso-button-logo"
         [hidden]="ssoProviderSelected === provider"
    />
    <img src="/assets/img/Spin-1s-100px.svg"
         alt="{{provider.displayName}} loading spinner"
         class="sso-button-spinner"
         [hidden]="!isLoadingSsoRedirect || ssoProviderSelected !== provider"
    />
    <span class="sso-button-button">
      Sign in with {{ provider.displayName }}
    </span>
  </button>
</div>
