<nav class="loginHeader fixed-top bg-tertiary" aria-hidden="true">&nbsp;</nav>
<section id="loginFormContainer" class="d-flex flex-column align-items-center justify-content-center studentBackground w-100">
  <div class="loginLogoContainer mx-auto">
    <img id="primaryLogo" src="assets/img/skof-login-logo.png" alt="My ZB Portal Superkids Online Fun Login" class="mx-auto d-block loginImage">
  </div>
  <aside id="passwordResetMessage" *ngIf="showPasswordResetMessage" class="loginForm text-center my-5">
    If you can’t remember your password, please ask your teacher to reset it for you.
    <br />
    <button id="dismissResetMessage" (click)="showPasswordResetMessage=false" class="btn btn-outline-tertiary text-uppercase btn-sm mt-3">OK</button>
  </aside>
  <div id="error" *ngIf="error" class="alert alert-danger mx-auto" [innerHTML]="error"></div>
  <div id="'studentLoginContainer" class="d-flex flex-wrap justify-content-between align-items-start w-100">
    <div *ngIf="!isScanningQRCode" class="w-50 h-100 vertical-separator">
      <form *ngIf="!showPasswordResetMessage && form" id="skofLoginForm" class="loginForm text-center my-5 px-4" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="mb-3 w-50 mx-auto">
          <input
            aria-label="username"
            placeholder="username"
            type="text"
            class="form-control form-control-tertiary"
            id="username"
            name="username"
            required
            formControlName="username"
            [class.is-invalid]="(form.get('username').invalid && form.get('username').touched)" />
        </div>
        <div class="mb-3 w-50 mx-auto">
          <input
          aria-label="password"
          placeholder="password"
          type="password"
          class="form-control form-control-tertiary"
          id="password"
          name="password"
          autocomplete="off"
          required
          formControlName="password"
          [class.is-invalid]="(form.get('password').invalid && form.get('password').touched)" />
        </div>
        <div class="mb-3 w-50 mx-auto">
          <input
            aria-label="School ID"
            placeholder="School ID"
            type="text"
            class="form-control form-control-tertiary"
            id="schoolId"
            required
            name="schoolId"
            formControlName="schoolId"
            [class.is-invalid]="(form.get('schoolId').invalid && form.get('schoolId').touched)" />
        </div>
        <div id="links" class="mb-3 w-50 mx-auto text-end">
          <button id="forgotPassword" type="button" (click)="showPasswordResetMessage=true" class="d-inline-block mb-2 btn btn-link">Forgot your password?</button>
        </div>
        <button id="loginSubmit" [disabled]="loginProcessing" type="submit" class="btn btn-outline-tertiary text-uppercase btn-sm" [attr.aria-disabled]="!form.valid">Log In</button>
      </form>
    </div>

    <div *ngIf="!isScanningQRCode" class="w-50 h-100">
      <div class="d-flex flex-column justify-content-between align-items-start">
        <img class="my-5 qr-code-help w-100" src="assets/img/QRcode_graphic.png" alt="" (click)="getQRCodeHelp()"/>
        <div class="button-wrapper w-50">
          <button id="scanYourBadge" (click)="prepQRCodeScan()" class="btn btn-outline-tertiary text-uppercase btn-sm mt-3">Scan Your QR Code</button>
        </div>
      </div>
    </div>
  </div>

  <div class="w-100">
    <div class="d-flex flex-column justify-content-between align-items-start">
      <!-- need to use the style attribute as it overrides the underlying canvas and video elements styling - class-based CSS ineffective -->
      <div id="qrCodeScanner"Z style="width: 400px; height: auto; margin: auto"></div>
      <div *ngIf="isScanningQRCode" class="w-100 py-2 text-center">
        <button id="cancelScan" (click)="cancelQRCodeScan()" class="btn btn-outline-tertiary text-uppercase btn-sm mt-3">Cancel</button>
      </div>
    </div>
  </div>

</section>

<zbp-footer [showLinks]="false" [isAndroidUser]="isAndroidUser" background="bg-tertiary"></zbp-footer>
