import { Component, OnInit } from '@angular/core';
import { AppConfigService } from '@core/appconfig.service';
import { AuthenticationService } from '@core/authentication.service';
import { FeatureFlagService } from '@core/feature-flag.service';
import { ZbPortalRedesignHelpers } from '@core/services/feature-flagged-features/zb-portal-redesign-helpers';
import { environment } from '@environments/environment';
import { CompanyCode } from '@shared/enums/company-code';
import { FeatureFlagType } from '@shared/enums/feature-flag-type';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { ToastrService } from 'ngx-toastr';
import { skipWhile } from 'rxjs/operators';

@Component({
  selector: 'zbp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    public authService: AuthenticationService,
    public appConfig: AppConfigService,
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    public featureFlagService: FeatureFlagService,
    public toastr: ToastrService,
    private zbRedesignHelpers: ZbPortalRedesignHelpers,
  ) { }

  ngOnInit() {
    this.appConfig.setBrandFromUrl();

    this.featureFlagService.featureFlagsLoaded$
      .pipe(
        skipWhile(loaded => !loaded),
      )
      .subscribe(() => {
        if (environment.environment !== 'prod') {
          console.log(`The following features are activated: ${this.featureFlagService.getActiveFlags()}`);
        }
        if (this.featureFlagService.isActive(FeatureFlagType.UseAPIProxyPortal)) {
          console.log(`API Proxy engaged`);
          if (environment.proxyApiUrl) {
            this.appConfig.useProxyApi(true);
          }
        }
        if (this.appConfig.loginBrand === CompanyCode.HighlightsPortal
          || this.zbRedesignHelpers.isZbPortal2024Active()) {
          this.toastr.toastrConfig.positionClass = 'toast-bottom-center';
        }
      });
    this.angulartics2GoogleTagManager.startTracking();
  }
}
