import { Injectable } from '@angular/core';

import { AnalyticsSettings } from '@models/analytics-settings';
import { IClassroom } from '@models/classroom';
import { IProductComponent } from '@models/product-component';
import { Helpers } from '@shared/helpers';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import * as _ from 'lodash';

import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(private userService: UserService, private angulartics2GoogleTagManager: Angulartics2GoogleTagManager) {}

  setUserProperties(productLineKey?: string, product?: IProductComponent, classroom?: IClassroom): void {
    const settings = {
      dimensions: [],
      metrics: [],
    } as AnalyticsSettings;

    if (this.userService.user) {
      if (this.userService.user.roles.length > 0) {
        settings.dimensions.push({ key: '1', value: _.last(this.userService.user.roles) });
      }
      if (this.userService.user.abbreviatedSchoolId) {
        settings.dimensions.push({ key: '2', value: this.userService.user.abbreviatedSchoolId });
      }
    }

    if (classroom) {
      settings.dimensions.push({ key: '4', value: Helpers.mapGradeTypeToGrade(classroom.gradeDesignation) });
    }

    if (productLineKey) {
      settings.dimensions.push({ key: '5', value: productLineKey });
    }
    if (product) {
      settings.dimensions.push({ key: '6', value: product.name });
    }

    this.angulartics2GoogleTagManager.eventTrack('setUserProperties', settings);
  }
}
