import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import {
  AbstractControl, ControlContainer, FormArray,
  FormBuilder, FormControl, FormGroup,
  ReactiveFormsModule,
  ValidationErrors, ValidatorFn, Validators
} from '@angular/forms';
import { AppConfigService } from '@core/appconfig.service';
import { UserService } from '@core/user.service';
import { FormFieldModel } from '@models/form-field';
import { School } from '@models/school';
import { UserPartial } from '@models/user';
import { NgbModalRef, NgbModule, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ButtonComponent } from '@shared/button/button.component';
import { RoleType } from '@shared/enums/role-type';
import { FormHelpers } from '@shared/form-helpers';
import { passwordRequirements } from '@shared/password-requirement-list';
import { UserSearchWithDropdownComponent } from '@shared/user-search-with-dropdown/user-search-with-dropdown.component';
import { zbpIconNames } from '@shared/zbp-icon/zbp-icon-names';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Subscription } from 'rxjs';
import { filter,  tap } from 'rxjs/operators';

import { generalUserFields, primaryFormControlNames, studentUserFields } from './helpers/form-field-setup-data';
import { getParentFieldsValidator } from './helpers/form-validators';

/**
 * Form handles creating all school-related users. Reusable and can be added to any existing form.
 *
 * Utilizes ControlContainer to access its containing form and add a new group to the existing form.
 *
 * By default, the formGroup will be added with a name of 'newUsersGroup'.
 *
 * Can also provide a custom name (newUsersFormGroupName) to reference this form group by its containing form.
 * To access this group in any other part using its form group name.
 *
 * **Provide a unique newUsersFormGroupName when adding more than 1 instance of this component to a containing form.**
 *
 * The below example uses 'formName' as the example variable name for a containing form:
 *
 *       formName.get('theValueOfNewUsersFormGroupName')
 */
@Component({
  standalone: true,
  selector: 'zbp-branded-create-user-form',
  templateUrl: './create-user-form.component.html',
  styleUrls: ['./create-user-form.component.scss'],
  imports: [ButtonComponent, ReactiveFormsModule, CommonModule, NgbModule, UserSearchWithDropdownComponent,]
})
export class CreateUserFormComponent implements OnDestroy, OnInit, OnChanges {
  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private toastr: ToastrService,
    private appConfigService: AppConfigService,
    private controlContainer: ControlContainer
  ) { }

  /** Role type to add for the user. */
  @Input() roleTypeToAdd: RoleType = null;
  /** School the user will belong to. */
  @Input() school: School;
  /** Specify the min and max number of users */
  @Input() minRequired: number = 1;
  @Input() maxRequired: number = undefined;
  /** Flag to indicate if multiple users can be added. */
  @Input() canAddMultipleUsers: boolean = false;
  /** Flag to hide the delete button on the first row. */
  @Input() hideFirstRowDeleteButton: boolean = false;
  /** Name of the form group for new users. How the group can be referenced by its containing form. */
  @Input() newUsersFormGroupName = 'newUsersGroup';
  /** Label for the "Add Another" button if canAddMultipleUsers is true. */
  @Input() addAnotherUserLabel = 'Add Another';
  /** Flag for displaying existing user search */
  @Input() canUserSearch = false;
  /** Flag for if userId should be included in use form data */
  @Input() includeUserId = false;

  educationalUnitId: string = null;
  isSearching = false;
  usesOneRoster = false;
  passwordRequirements: string[] = passwordRequirements;
  formFieldNames: { [key: string]: FormFieldModel };
  confirmModal: NgbModalRef;
  isAddingDistrictAdmin: boolean = false;
  isAddingSchoolAdmin: boolean = false;
  isAddingTeacher: boolean = false;
  isAddingStudent: boolean = false;
  isAddingParent: boolean = false;
  initialConfigurationComplete: boolean = false;
  newUserFormGroup: FormGroup;
  form: FormGroup;
  searchBox: FormControl = new FormControl();

  protected readonly zbpIconNames = zbpIconNames;
  protected readonly RoleType = RoleType;

  private subscriptions: Subscription[] = [];

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnInit(): void {
    this.formFieldNames = generalUserFields;

    const controlContainerAsFormGroup = this.controlContainer.control as FormGroup;
    this.setupIsAddingRoleType();

    if (this.roleTypeToAdd) {
      this.setupFormGroup();
      this.form.get(this.formFieldNames.roleType.name).setValue(this.roleTypeToAdd);
      // Prepopulate minimum number of rows if no users already exist in form
      if (this.newUsers?.length === 0) {
        this.addMinimumNumberOfRows();
      }
    }

    if (this.school) {
      this.educationalUnitId = this.school.schoolId;
      this.usesOneRoster = this.school.isOneRoster;
    }

    if (this.isAddingStudent && !this.school?.abbreviatedSchoolId) {
      this.toastr.error('You must set an abbreviated school id for this school prior to adding students.');
      this.form.disable();
    }

    this.newUserFormGroup = controlContainerAsFormGroup.get(this.newUsersFormGroupName) as FormGroup;
    this.initialConfigurationComplete = true;
  }

  /**
   * Updates the form group based on any roleType changes after the initial form has been configured
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.roleTypeToAdd && this.initialConfigurationComplete) {
      this.roleTypeToAdd = changes.roleTypeToAdd.currentValue;
      this.setupIsAddingRoleType();
      this.resetUsersForm();
      this.form.get(primaryFormControlNames.roleType).setValue(this.roleTypeToAdd);
    }
  }

  /**
   * Sets up the role type flags based on the roleTypeToAdd input.
   */
  private setupIsAddingRoleType(): void {
    this.isAddingDistrictAdmin = false;
    this.isAddingSchoolAdmin = false;
    this.isAddingTeacher = false;
    this.isAddingStudent = false;
    this.isAddingParent  = false;

    if (this.roleTypeToAdd === RoleType.DistrictAdministrator) {
      this.isAddingDistrictAdmin = true;
    } else if (this.roleTypeToAdd === RoleType.SchoolAdministrator) {
      this.isAddingSchoolAdmin = true;
    } else if (this.roleTypeToAdd === RoleType.Teacher) {
      this.isAddingTeacher = true;
    } else if (this.roleTypeToAdd === RoleType.Parent) {
      this.isAddingParent = true;
    } else if (this.roleTypeToAdd === RoleType.Student) {
      this.isAddingStudent = true;
    }
  }

  /**
   * Sets up the form group for new users.
   * All validation for this group is handled within this form.
   * Validation can be referenced by its containing form for complete form validation.
   */
  private setupFormGroup() {
    const controlContainerAsFormGroup = this.controlContainer.control as FormGroup;
    const currentFormForThisComponent = controlContainerAsFormGroup?.get(this.newUsersFormGroupName) as FormGroup;
    // If this form isn't already on the Control Container, instantiate newUsers & roleType
    const userListValidators: ValidatorFn[] = [];
    if (this.minRequired) {
      userListValidators.push(FormHelpers.getArrayMinValidator(this.minRequired));
    }
    if (this.maxRequired) {
      userListValidators.push(FormHelpers.getArrayMaxValidator(this.maxRequired));
    }
    if (!currentFormForThisComponent) {
      this.form =  new FormGroup({
        newUsers: new FormArray([], userListValidators),
        roleType: new FormControl(null),
      });
      controlContainerAsFormGroup.addControl(this.newUsersFormGroupName, this.form);
    } else {
      // Use the existing form group data if it already exists.
      this.form = currentFormForThisComponent;
    }
  }

  /**
   * Gets the FormArray of users being added
   * @returns {FormArray} The FormArray for new users.
   */
  get newUsers(): FormArray {
    return this.form.get(primaryFormControlNames.newUsers) as FormArray;
  }

  /**
 * Checks if the form has password fields.
 * Not all roleTypes have password fields.
 * @returns {boolean} True if the form has password fields, false otherwise.
 */
  get hasPasswordFields(): boolean {
    return this.isAddingStudent;
  }

  /**
 * Checks if the form has parent fields.
 * Not all roleTYpes have parent fields.
 * @returns {boolean} True if the form has parent fields, false otherwise.
 */
  get hasParentFields(): boolean {
    return this.hasPasswordFields && this.canManageParent;
  }

  /**
 * Checks if the user can manage parents.
 * @returns {boolean} True if the user can manage parents, false otherwise.
 */
  get canManageParent(): boolean {
    return this.userService.user.profileDetail?.canManageParent;
  }

  /**
   * Adds another user to the newUsers form array.
   */
  addAnother(): void {
    this.newUsers.push(this.addNewUserRow());
  }

  /**
 * Removes a user row from the newUsers form array.
 * @param {number} index - The index of the row to remove.
 */
  removeRow(index: number): void {
    this.newUsers.removeAt(index);
  }

  /**
   * Gets the student form control for specified index in the newUsers form array.
   * @param {number} i - The index of the form control.
   * @returns {FormControl} The student form control.
   */
  getStudentFormControl(i): FormControl {
    return this.newUsers.at(i).get(this.formFieldNames.student.name) as FormControl;
  }

  /**
   * Clears newUsers form array.
   * Then adds a number of rows equal to the minimum required configuration.
  */
  resetUsersForm(): void {
    this.newUsers.clear();
    this.addMinimumNumberOfRows();
  }

  /**
   * Adds a number of new rows equal to the minimum required configuration.
   */
  addMinimumNumberOfRows(): void {
    if (this.minRequired > 0) {
      for (let i = 0; i < this.minRequired; i++) {
        this.newUsers.push(this.addNewUserRow());
      }
    }
  }

  /**
   * Checks if a form control is invalid and touched.
   * @param {number} index - The index of the form control.
   * @param {string} name - The name of the form control.
   * @returns {boolean} True if the form control is invalid and touched, false otherwise.
   */
  formControlInvalidAndTouched(index: number, name: string): boolean {
    return FormHelpers.formControlInvalidAndTouched(this.newUsers, index, name);
  }

  /**
   * Checks if a form control is invalid for a specific user within the newUsers form array.
   * @param {number} index - The index of the newUser form array.
   * @param {string} name - The name of the form control for the specific user in the newUser form array.
   * @returns {boolean} True if the form control is invalid, false otherwise.
   */
  formControlInvalid(index: number, name: string): boolean {
    return FormHelpers.formControlInvalid(this.newUsers, index, name);
  }

  /**
 * Checks if the confirm password field is invalid.
 * Confirm Password must validate when not touched if user adds a Password
 * @param {number} index - The index of the newUser form array.
 * @returns {any} The validation errors or null.
 */
  confirmPasswordInvalid(index: number): any {
    return FormHelpers.confirmPasswordInvalid(this.newUsers, index, this.formFieldNames.confirmPassword.name);
  }

  /**
   * Checks if the student form control is invalid.
   * @param {FormControl} control - The form control.
   * @returns {boolean} True if the form control is invalid, false otherwise.
   */
  studentFormControlInvalid(control: FormControl): boolean {
    return FormHelpers.studentFormControlInvalid(control);
  }

  /**
 * Validator function for the student form control.
 * @returns {ValidatorFn} The validator function.
 */
  studentFormControlValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isValid = control.value !== null && typeof control.value !== 'string';
      return !isValid ? { invalidStudent: `The student "${control.value}" is not a valid student` } : null;
    };
  }

  /**
 * Gets the error text for the confirm password field.
 * @param {number} index - The index of the newUser form array.
 * @returns {string} The error text.
 */
  confirmPasswordFieldErrorText(index: number): string {
    return FormHelpers.confirmPasswordFieldErrorText(this.newUsers, this.formFieldNames, index);
  }

  /**
 * Gets the error text for the name field.
 * @param {number} index - The index of the newUser form array.
 * @param {string} name - The name of the form control for the specific user in the newUser form array.
 * @returns {string} The error text.
 */
  nameFieldErrorText(index: number, name: string): string {
    return FormHelpers.nameFieldErrorText(this.newUsers, this.formFieldNames, index, name);
  }

  /**
   * Adds standard name-related fields and validators to this form group based on roleType.
   */
  private setupBasicUserFields() {
    const usernameValidators = [Validators.required];
    const nameValidators = [Validators.required, FormHelpers.getMaxLengthNameValidator()];

    if (this.roleTypeToAdd === RoleType.Student) {
      usernameValidators.push(FormHelpers.getStudentUsernameValidator());
      this.setupStudentFieldNames();
    } else {
      usernameValidators.push(Validators.email);
      this.formFieldNames = generalUserFields;
    }

    this.newUserFormGroup = this.fb.group({
      firstName: ['', nameValidators],
      lastName: ['', nameValidators],
      username: ['', usernameValidators],
    });
  }

  /**
   * Adds passwords fields and validators to this form group
   */
  private setupPasswordFields() {
    this.newUserFormGroup.addControl(this.formFieldNames.externalId.name, this.fb.control(''));

    this.newUserFormGroup.addControl(
      this.formFieldNames.password.name,
      this.fb.control('', FormHelpers.getPasswordValidator(true))
    );

    this.newUserFormGroup.addControl(
      this.formFieldNames.confirmPassword.name,
      this.fb.control('', [FormHelpers.getConfirmPasswordValidator(true)])
    );

    const passwordControl = this.newUserFormGroup.get(this.formFieldNames.password.name);

    this.subscriptions.push(
      passwordControl?.valueChanges.subscribe(() => {
        this.newUserFormGroup.get(this.formFieldNames.confirmPassword.name)?.updateValueAndValidity();
      })
    );
  }

  /**
   * Adds fields and validators needed to manage a parent when adding a role that requires a parent
   */
  private setupManageParentFields() {
    // Custom validator is added for the parents field as all 3 are dependent on one another
    // Initial validation is empty when creating fields.
    this.newUserFormGroup.addControl(this.formFieldNames.parentFirstName.name, this.fb.control('', []));
    this.newUserFormGroup.addControl(this.formFieldNames.parentLastName.name, this.fb.control('', []));
    this.newUserFormGroup.addControl(this.formFieldNames.parentUsername.name, this.fb.control('', []));

    // Added at the form level for performance reasons
    this.newUserFormGroup.addValidators(getParentFieldsValidator());

    const parentFirstNameControl = this.newUserFormGroup.get(this.formFieldNames.parentFirstName.name);
    const parentLastNameControl = this.newUserFormGroup.get(this.formFieldNames.parentLastName.name);
    const parentUsernameControl = this.newUserFormGroup.get(this.formFieldNames.parentUsername.name);

    // Whenever 1 of these fields is changed, all 3 need to run validation
    combineLatest([
      parentFirstNameControl?.valueChanges,
      parentLastNameControl?.valueChanges,
      parentUsernameControl?.valueChanges
    ]).pipe(
      filter(([parentFirstName, parentLastName, parentUsername]) => parentFirstName
        && parentLastName
        && parentUsername
      ),
      tap(() => {
        this.form.updateValueAndValidity();
      })
    ).subscribe();
  }

  /**
   * Adds fields and validators when adding a Parent roleType.
   */
  private setupParentFields() {
    this.newUserFormGroup.addControl(
      this.formFieldNames.student.name,
      this.fb.control('', [this.studentFormControlValidator(), Validators.required]
      ));
  }

  /**
   * Updates First and Last Name fields to prepend "Student"
   */
  private setupStudentFieldNames() {
    this.formFieldNames = {
      ...generalUserFields,
      ...studentUserFields,
    };
  }

  /**
   * Adds a new form row to this form group and set up its fields & validators based on roleType
   * @returns This form group with new row added.
   */
  private addNewUserRow(): FormGroup {
    this.setupBasicUserFields();

    if (this.hasPasswordFields) {
      this.setupPasswordFields();

      if (this.canManageParent) {
        this.setupManageParentFields();
      }
    }

    if (this.roleTypeToAdd === RoleType.Parent) {
      this.setupParentFields();
    }

    if (this.includeUserId) {
      this.newUserFormGroup.addControl(this.formFieldNames.userId.name, this.fb.control('', []));
    }

    return this.newUserFormGroup;
  }

  userSearchSelectionFunction = (event) => {
    if (this.roleTypeToAdd === RoleType.Student) {
      this.addExistingStudent(event);
    } else if (this.roleTypeToAdd === RoleType.Teacher) {
      this.addExistingTeacher(event);
    } else {
      throw new Error('Role Type not implemented');
    }
  };

  /**
   * Adds an existing teacher from typeahead selection.
   *
   * @param {NgbTypeaheadSelectItemEvent} event Special event with item and preventDefault properties.
   */
  addExistingTeacher(event: NgbTypeaheadSelectItemEvent): void {
    const { item } = event;
    const notFound = this.newUsers.value.findIndex(u => u?.username === item?.username) === -1;
    if (notFound) {
      const lastRowIndex = this.newUsers.length - 1;
      if (!(lastRowIndex < 0) && this.formRowHasEmptyValues(lastRowIndex, this.newUsers)) {
        // Updates existing empty row instead of adding a new one.
        this.updateTeacherRow(lastRowIndex, {
          ...item,
          userName: item.username,
        } as UserPartial);
      } else {
        this.addAnother();
        this.updateTeacherRow(lastRowIndex + 1, {
          ...item,
          userName: item.username,
        } as UserPartial);
      }
      this.newUsers
        .at(this.newUsers.length - 1)
        .get('username')
        .markAsDirty();
      this.newUsers.updateValueAndValidity();
    } else {
      this.toastr.warning(
        `${item?.username} is already a teacher for this classroom.`
      );
    }
  }

  /**
   * Fills in values to an existing teacher row.
   *
   * @param {number} index
   * @param {UserPartial} data
   */
  updateTeacherRow(index: number, data: UserPartial): void {
    this.newUsers.at(index).setValue({
      firstName: data.firstName,
      lastName: data.lastName,
      username: data.userName,
    });
  }

  /**
   * Add an existing student from typeahead selection.
   *
   * @param {NgbTypeaheadSelectItemEvent} event
   */
  addExistingStudent(event: NgbTypeaheadSelectItemEvent): void {
    const { item } = event;
    const notFound = this.newUsers.value.findIndex(u => u?.userId === item?.userId) === -1;
    if (notFound) {
      const lastRowIndex = this.newUsers.length - 1;
      if (!(lastRowIndex < 0) && this.formRowHasEmptyValues(lastRowIndex, this.newUsers)) {
        // Updates existing empty row instead of adding a new one.
        this.updateStudentRow(lastRowIndex, {
          ...item,
          userName: item.username,
        } as UserPartial);
      } else {
        this.addAnother();
        this.updateStudentRow(lastRowIndex + 1, {
          ...item,
          userName: item.username,
        } as UserPartial);
      }
      this.newUsers
        .at(this.newUsers.length - 1)
        .get('username')
        .markAsDirty();
      this.newUsers.updateValueAndValidity();
    } else {
      this.toastr.warning(
        `${item?.username} is already a student in this classroom.`
      );
    }
  }

  /**
   * Checks if a form group (row) within the given form array has empty string values.
   *
   * @param {number} index
   * @param {FormArray} form
   */
  private formRowHasEmptyValues(index: number, form: FormArray): boolean {
    let isEmpty = true;
    const values = { ...form.at(index).value };
    Object.keys(values).forEach((key: string) => {
      if (
        Object.prototype.hasOwnProperty.call(values, key)
        && !!values[key] && values[key].length > 0
      ) {
        isEmpty = false;
      }
    });
    return isEmpty;
  }

  /**
   * Fills in values to an existing student row.
   *
   * @param {number} index
   * @param {UserPartial} data
   */
  updateStudentRow(index: number, data: UserPartial): void {
    this.newUsers.at(index).patchValue({
      userId: data.userId,
      firstName: data.firstName,
      lastName: data.lastName,
      username: data.studentUserName,
      externalId: data.externalId || null,
      password: '',
      confirmPassword: '',
    });
  }
}
