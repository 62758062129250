import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AuthenticationService } from '@core/authentication.service';
import { CacheService } from '@core/cache.service';
import { ApiResponse } from '@models/api-response';
import { IClassroom } from '@models/classroom';
import { IClassroomProductDescription } from '@models/classroom-products';
import { KeyValuePair } from '@models/key-value-pair';
import { ILicense } from '@models/license/license';
import { LicenseAssignee } from '@models/license/license-assignee';
import { SchoolLicense } from '@models/license/school-license';
import { IProduct, Product, ProductSku } from '@models/product';
import { IProductComponent } from '@models/product-component';
import { ProductLicense } from '@models/product-license';
import { IProductLine } from '@models/product-line';
import { IProductVariant } from '@models/product-variant';
import { QuickLesson } from '@models/quick-lesson';
import { QuickLessonData } from '@models/quick-lesson-data';
import { School } from '@models/school';
import { ISpellingWord } from '@models/spelling-word';
import { StudentRank } from '@models/student-rank';
import {
  NavigationPagedItemResponse,
  NavigationPagedResponse,
  NavigationResponse,
  Topic,
  TopicGroup
} from '@models/topic';
import { TypeResponseMessage } from '@models/type-response-message';
import { IViewerAsset, IViewerAssetJson, ViewerAsset } from '@models/viewer-asset';
import { GradeType } from '@shared/enums/grade-type';
import { LaunchType } from '@shared/enums/launch-type';
import { LicenseFilterType } from '@shared/enums/license-filter-type';
import { LicenseAssignmentType } from '@shared/enums/license-status';
import { ProductKey } from '@shared/enums/product-key';
import { ProductType } from '@shared/enums/product-type';
import { SpellingLevelType } from '@shared/enums/spelling-level-type';
import { StateType } from '@shared/enums/state-type';
import { TopicType } from '@shared/enums/topic-type';
import { VariantType } from '@shared/enums/variant-type';
import { ViewerAssetType, ViewerLanguage } from '@shared/enums/viewer-asset';
import { Helpers } from '@shared/helpers';
import { copyArray, copyObject } from '@shared/zb-object-helper/object-helper';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AppConfigService } from './appconfig.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  /**
   * All unexpired product licenses across all product lines
   */
  activeProductLicenses: ProductLicense[] = [];

  licensesLoaded: BehaviorSubject<boolean>;
  productLines$ = new BehaviorSubject<IProductLine[]>(null);
  variantType$ = new BehaviorSubject<VariantType>(null);

  showClassesAndReports = false;

  readonly handwritingProducts: ProductType[] = [
    ProductType.hw2020n,
    ProductType.hw2025n,
    ProductType.hw2020tx,
    ProductType.hw2020txs,
    ProductType.laesc2020n,
    ProductType.laesc2020tx,
    ProductType.laesc2020txs,
  ];

  readonly superkidsProducts: ProductType[] = [
    ProductType.hea2011,
    ProductType.sk2015,
    ProductType.sk2017,
    ProductType.sk2026,
    ProductType.fsk2021,
  ];

  /**
   * Product component launch types that appear on the Materials page by default.
   */
  readonly materialLaunchTypes = [
    LaunchType.DailyRoutine,
    LaunchType.SuperKidsOnlineFun,
    LaunchType.SuperkidsOwnWebPages,
    LaunchType.SuperSmart,
    LaunchType.Viewer,
    LaunchType.WhiteboardActivity,
    LaunchType.NumberPractice,
    LaunchType.MatchingWithZaney,
    LaunchType.WorksheetMaker,
  ];

  readonly genericLockedReason: string = `<p>These units have been locked by your teacher.</p>
    <p>You can unlock these units by first completing the units available to you.</p>`;

  readonly specialCaseProductKeys = [ProductKey.ms2024.toString()];

  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService,
    private cache: CacheService,
    private authService: AuthenticationService,
    private userService: UserService,
  ) {
    this.licensesLoaded = new BehaviorSubject<boolean>(false);
  }

  private getContentManifestUrl(manifestFile: string): string {
    return `${this.appConfig.assetUrl}content/${manifestFile}`;
  }

  private getQuickLessonsUrl(productType: string, variantKey: string): string {
    return `${this.appConfig.apiUrl}/digital-resources/teacher/handwriting/quick-lessons/product/${productType}/grade/${variantKey}`;
  }

  private getTopicsApiUrl(productType: ProductType, grade: string, classroomId: string): string {
    const query = `?grade=${grade}&productType=${productType}`;
    return `${this.appConfig.apiUrl}/digital-resources/navigation/classroom/${classroomId}${query}`;
  }

  private getLeaderboardUrl(classroomId: string, productType: ProductType): string {
    return `${this.appConfig.apiUrl}/student/leaderboard/${classroomId}/product/${productType}`;
  }

  private getProductsUrl(): string {
    return `${this.appConfig.apiUrl}/product/navigation`;
  }

  // @todo Api should not use superkids-specific content service for viewer asset.
  private getViewerAssetsUrl(productType: ProductType, variantType: VariantType | string, type: string = 'viewer-asset', classroom: IClassroom = null): string {
    const url = `${this.appConfig.apiUrl}/digital-resources/superkids/${type}/product/${productType}/grade/${variantType}`;

    if (classroom) {
      // @todo Optionally add the classroom id in case the Api needs it later.
      return `${url}?classroomId=${classroom.classroomId}`;
    }

    if (productType === ProductType.ece2024) {
      return `${this.appConfig.apiUrl}/digital-resources/${type}/product/${productType}/grade/${variantType}`;
    }
    return url;
  }

  updateUserSchoolLicenses(schoolId: string, userId: string, assignments: LicenseAssignee[], assignmentType: LicenseAssignmentType = null): Observable<ApiResponse<SchoolLicense[]>> {
    const params: KeyValuePair[] = [{ key: 'userId', value: userId }];
    if (assignmentType) {
      params.push({ key: 'assignmentType', value: assignmentType });
    }
    const url = Helpers.buildUrlWithParameters(this.appConfig.apiUrl, `/educational-unit/${schoolId}/license`, params);
    return this.http.patch<ApiResponse<ILicense[]>>(url, assignments)
      .pipe(
        map(res => new ApiResponse<SchoolLicense[]>(true, {
          response: res.response.map(v => copyObject(v, SchoolLicense)),
          messages: res.messages,
        })),
      );
  }

  updateUserSchoolLicense(schoolId: string, userId: string, skuId: string, isAssigned: boolean): Observable<ApiResponse<SchoolLicense[]>> {
    const assignments: LicenseAssignee[] = [{ skuId, isAssigned, assignedId: userId }];
    return this.updateUserSchoolLicenses(schoolId, userId, assignments);
  }

  assignMultipleUserSchoolLicense(schoolId: string, userId: string, skuIds: string[], isAssigned: boolean): Observable<ApiResponse<SchoolLicense[]>> {
    const assignments: LicenseAssignee[] = [];
    skuIds.forEach(skuId => assignments.push({ skuId, isAssigned, assignedId: userId }));
    return this.updateUserSchoolLicenses(schoolId, userId, assignments);
  }

  getAvailableUserSchoolLicenses(schoolId: string, userId: string): Observable<ApiResponse<SchoolLicense[]>> {
    const params: KeyValuePair[] = [
      { key: 'userId', value: userId },
      { key: 'licenseFilterType', value: LicenseFilterType.ShowUnassigned },
    ];
    const url = Helpers.buildUrlWithParameters(this.appConfig.apiUrl, `/educational-unit/${schoolId}/license`, params);
    return this.http.get<ApiResponse<ILicense[]>>(url)
      .pipe(
        map(res => new ApiResponse<SchoolLicense[]>(true, {
          response: res.response.map(v => copyObject(v, SchoolLicense)),
          messages: [],
        })),
      );
  }

  // Maps all available licenses for a user across a setof schools by school identifier.
  getUserSchoolLicenses(schools: School[], userId: string): Observable<KeyValuePair[]> {
    const obs = schools.map((school) => {
      const url = `${this.appConfig.apiUrl}/educational-unit/${school.schoolId}/license?userId=${userId}`;
      return this.http.get<ApiResponse<ILicense[]>>(url)
        .pipe(
          map(res => (
            { key: school.schoolId, value: res.response.map(v => copyObject(v, SchoolLicense)) })),
        );
    });

    return combineLatest(obs);
  }

  getProducts(): Observable<ApiResponse<IProductLine[]>> {
    return this.http.get(this.getProductsUrl())
      .pipe(
        map((res: ApiResponse<IProductLine[]>) => new ApiResponse<IProductLine[]>(true, {
          response: res.response.map((pl) => {
            const variants = pl.variants.map((variant) => {
              const skus = variant.skus.map(sku => new ProductSku(sku));
              return { ...variant, skus };
            });
            return { ...pl, variants };
          }),
          messages: res.messages,
        })),
      );
  }

  getProductsWithTeacherLicenses(productLines: IProductLine[]): IProductLine[] {
    let productLineFiltered: IProductLine;
    const productLinesFiltered: IProductLine[] = [];
    productLines.forEach((productLine) => {
      const productType = Helpers.getProductTypeFromProductLineId(productLine.productLineKey);
      const isNotSuperKids = Helpers.supplementalProductTypes.includes(productType);
      let variantFiltered: IProductVariant;
      const variantsFiltered: IProductVariant[] = [];
      productLine.variants.forEach((variant) => {
        const skus = variant.skus
          .filter(sku => sku.licenseAssignmentType === LicenseAssignmentType.Teacher
            || (sku.licenseAssignmentType === LicenseAssignmentType.Classroom && isNotSuperKids));
        if (skus.length > 0) {
          variantFiltered = cloneDeep(variant);
          variantFiltered.skus = skus;
          variantsFiltered.push(variantFiltered);
        }
      });
      if (variantsFiltered.length > 0) {
        productLineFiltered = cloneDeep(productLine);
        productLineFiltered.variants = variantsFiltered;
        productLinesFiltered.push(productLineFiltered);
      }
    });
    return productLinesFiltered;
  }

  /**
   * Gets the Viewer asset list for a particular grade based on the path provided.
   */
  getContentManifest(basePath: string): Observable<TypeResponseMessage<Array<IViewerAssetJson>>> {
    return this.http.get(this.getContentManifestUrl(basePath)).pipe(
      map((response: any) => new TypeResponseMessage<Array<IViewerAssetJson>>(true, [], response)),
      catchError(error => (
        of(new TypeResponseMessage<Array<IViewerAssetJson>>(false, [error], []))
      )));
  }

  /**
   * Gets the Viewer content for a particular path filtered by language and edition.
   *
   * @deprecated replaced by ::getViewerAssetsFromApi and only used by supplemental legacy.
   */
  getViewerAssetsFromJson(productType: ProductType, componentId: string, gradeType: GradeType | string): Observable<ApiResponse<IViewerAsset[]>> {
    const productTypes = Object.keys(ProductType).filter(key => productType === ProductType[key]);
    const productLineId: string = productTypes.length > 0 ? productTypes[0] : '';
    const series = this.getViewerSeries(productLineId);
    const normalizedSeries = this.getViewerSeriesDesignation(series);
    const normalizedGrade = Helpers.mapVariantToGradeType(gradeType);
    const basePath = `${normalizedGrade.toLocaleLowerCase()}/${normalizedSeries}/viewer/data.json`;
    const language = this.getLanguageFromSeries(series, componentId);
    const edition = this.getEditionFromProductLineId(productLineId);

    return this.getContentManifest(basePath)
      .pipe(
        map(response => (new ApiResponse<IViewerAsset[]>(response.success, {
          messages: [...response.errors],
          response: response.result
            .filter((asset: IViewerAssetJson) => (asset.language === language
              && (!asset.edition || asset.edition === edition || asset.edition === 'any')))
            .map((asset: IViewerAssetJson) => ViewerAsset.fromJson(componentId, asset)),
        })))
      );
  }

  /**
   * Gets the "Quick Lessons" unit/lesson list for a particular grade based on the path provided.
   */
  getQuickLessons(productType: string, variantKey: string, language: ViewerLanguage, edition: string): Observable<QuickLesson[]> {
    return this.getQuickLessonsFromApi(productType, variantKey).pipe(
      map((response) => {
        const assets = response.filter((m: QuickLessonData) => (
          language === m.language && (m.edition === 'any' || edition === m.edition)
        ));
        return assets.reduce((result: QuickLesson[], asset: QuickLessonData): QuickLesson[] => {
          const index = result.findIndex(l => l.lesson === asset.lesson && l.unit === asset.unit);
          if (index === -1) {
            result.push({
              unit: asset.unit,
              unit_name: asset.unit_name,
              lesson: asset.lesson,
              lesson_name: asset.lesson_name,
              language: asset.language,
              assets: [asset.id],
              edition,
            } as QuickLesson);
          } else {
            result[index].assets.push(asset.id);
          }
          return result.sort((a: QuickLesson, b: QuickLesson) => {
            let ret = 0;
            if (a.lesson < b.lesson) {
              ret = -1;
            } else if (a.lesson > b.lesson) {
              ret = 1;
            }
            return ret;
          });
        }, []);
      }));
  }

  getQuickLessonsFromApi(productType: string, grade: string): Observable<QuickLessonData[]> {
    return this.http.get(this.getQuickLessonsUrl(productType, grade))
      .pipe(
        map((response: ApiResponse<QuickLessonData[]>) => copyArray(response.response, QuickLessonData)),
        map(data => data.map(item => copyObject({
          ...item,
          language: item.language === ViewerLanguage.en ? ViewerLanguage.English : ViewerLanguage.Spanish,
        }, QuickLessonData)))
      );
  }

  /** Gets the spelling word list for a topic key, productType and level. */
  getSpellingWordList(topic: Topic, productType: ProductType): Observable<ApiResponse<ISpellingWord[]>> {
    const levelType = topic.productMetadata && topic.productMetadata.wordListLevel
      ? topic.productMetadata.wordListLevel
      : SpellingLevelType.OnLevel;
    const url = `${this.appConfig.apiUrl}/digital-resources/spelling/word-list/${topic.topicKey}`;
    const params = new HttpParams({
      fromObject: { levelType, productType },
    });
    return this.http.get(url, { params })
      .pipe(
        map(res => new ApiResponse<ISpellingWord[]>(true, res)),
      );
  }

  /**
   * Get the product path based on product component or asset.
   */
  getProductLaunchPathByComponentAsset(productLineKey: string, productComponent: IProductComponent, activity: string = null, asset: IViewerAsset = null, variantType: string = null): string {
    let path: string = '';
    const params: KeyValuePair[] = [
      { key: 'componentId', value: productComponent.componentId },
    ];

    if (productComponent.launchType === LaunchType.Viewer) {
      if (asset.viewerAssetType === ViewerAssetType.Download) {
        // Direct download.
        path = this.getDownloadableViewerAssetUrl(productLineKey, productComponent.componentId, asset, variantType);
      } else {
        const series = this.getViewerSeries(productLineKey);
        let { language } = asset;
        if (series === 'ms2024') {
          // Handles special case for Mindscapes.
          const assetArray = asset.assetId.split('_');
          const languageIndicator = assetArray[1];
          language = this.getLanguageFromSeries(series, languageIndicator);
        }

        params.push({ key: 'activity', value: activity });
        params.push({ key: 'assetId', value: asset.assetId });
        params.push({ key: 'language', value: language });
        path = this.getProductLaunchPath(productLineKey, productComponent, params);
      }
    } else if (productComponent.launchType === LaunchType.eBook) {
      params.push({ key: 'activity', value: 'ebook' });
      path = this.getProductLaunchPath(productLineKey, productComponent, params);
    } else if (productComponent.launchType === LaunchType.NumberPractice) {
      params.push({ key: 'activity', value: 'number-practice' });
      path = this.getProductLaunchPath(productLineKey, productComponent, params);
    } else {
      params.push({ key: 'activity', value: activity });
      path = this.getProductLaunchPath(productLineKey, productComponent, params);
    }

    return path;
  }

  /**
   * Get the product path based on product and optional parameters.
   */
  getProductLaunchPath(productLineKey: string, component: IProductComponent, srcParams: KeyValuePair[] = [], topic: Topic = null): string {
    const { origin } = window.location;
    let parameters: KeyValuePair[] = [...srcParams];
    let basePath = `${origin}/sites/digital-resources/`;
    const isFilamentActivity = topic?.topicType === TopicType.Trace
      || topic?.topicType === TopicType.FreeWrite
      || topic?.topicType === TopicType.CustomFreeWrite;

    if (isFilamentActivity) {
      // Removes componentId and adds parameters to help Filament.
      basePath = `${this.appConfig.assetUrl}sites/handwriting-trace/index.html`;
      const grade = this.getGradeDesignationFromComponentId(component.componentId);
      const productType = this.getProductTypeFromProductLine(productLineKey);

      parameters = parameters.filter(param => param.key !== 'componentId');
      parameters.push({ key: 'grade', value: Helpers.mapGradeToGradeType(grade) });
      parameters.push({ key: 'productType', value: productType });
      parameters.push({ key: 'apiUrl', value: `${this.appConfig.apiUrl}/` });
    }

    if (component.launchType === LaunchType.SuperKidsOnlineFun) {
      basePath = `${environment.skofUrl}/sites/skof/app/index.html`;
    }

    // send correct productType for texas products
    if (topic?.topicType === TopicType.Lesson && productLineKey.includes(StateType.TX)) {
      const productType = this.getProductTypeFromProductLine(productLineKey);
      parameters.push({ key: 'productType', value: productType });
    }

    // If localhost testing, add the token to the query string so the
    // Digital-Resources request can be authenticated.
    if (environment.environment.toLocaleLowerCase() !== 'prod'
      && (window.location.host.toLocaleLowerCase() === 'localhost:4200'
      || window.location.host.toLocaleLowerCase() === 'zbportal.internal:4200'
      || window.location.host.toLocaleLowerCase() === 'highlightsportal.internal:4200')) {
      parameters.push({ key: 'token', value: this.authService.apiToken });
    }

    return parameters.reduce((result, param, index) => {
      const { key, value } = param;
      const encoded = encodeURIComponent(value);
      if (index === 0) {
        return `${result}?${key}=${encoded}`;
      }
      return `${result}&${key}=${encoded}`;
    }, basePath);
  }

  /**
 * Get the product path based on product line, launchType and optional parameters.
 */
  getProductLaunchPathByProductLineLaunchTypeAndParams(productLineKey: string, launchType: LaunchType, srcParams: KeyValuePair[] = []): string {
    let path = '';

    const productComponent: IProductComponent = {
      productId: '',
      componentId: '',
      name: '',
      description: '',
      weight: 0,
      launchType,
      isActive: true,
      filterOptions: [],
      isPinned: false,
      roles: [],
    };

    path = this.getProductLaunchPath(productLineKey, productComponent, srcParams);

    return path;
  }

  /**
   * Use this to get the ViewerLanguage.
   */
  getViewerLanguageFromSeries(series: string): ViewerLanguage {
    return series.substring(0, 2) === 'la'
      ? ViewerLanguage.Spanish
      : ViewerLanguage.English;
  }

  /**
   * This should have only be used for legacy Handwriting content that uses legacy "en" and "es" codes.
   *
   * @deprecated
   */
  getLanguageFromSeries(series: string, languageIndicator: string): ViewerLanguage {
    if (this.specialCaseProductKeys.indexOf(series) !== -1) {
      return languageIndicator.toLocaleLowerCase().includes(ViewerLanguage.es) ? ViewerLanguage.es : ViewerLanguage.en;
    }
    return series.substring(0, 2) === 'la'
      ? ViewerLanguage.es
      : ViewerLanguage.en;
  }

  /** Extracts language code from a book ID. */
  getLanguageFromBookId(product: Product): string {
    return product.bookid.includes('_es_') ? ViewerLanguage.es : ViewerLanguage.en;
  }

  /**
   * Normalizes viewer language return from API.
   */
  getNormalizedLanguage(language: ViewerLanguage): ViewerLanguage {
    if (language === ViewerLanguage.English) {
      return ViewerLanguage.en;
    }
    if (language === ViewerLanguage.Spanish) {
      return ViewerLanguage.es;
    }
    return language;
  }

  getEditionFromProductLineId(productLineId: string): string {
    return productLineId.toLocaleLowerCase().includes('tx') ? 'tx' : 'ntl';
  }

  /** Extracts national or texas edition from a book ID. */
  getEdition(product: Product): string {
    return product.bookid.includes('_tx_') ? 'tx' : 'ntl';
  }

  getViewerSeries(productLineId: string): string {
    // The series prefix is no longer consistently lowercase and can be something like LaEsc, HW, or JIW.
    return productLineId.replace(/^([a-zA-Z]{2,}[0-9]{4}).*$/, '$1').toLocaleLowerCase();
  }

  /** Gets the 202x series designation for a product handwriting/spelling */
  getViewerSeriesDesignation(viewerSeries: string): string {
    if (viewerSeries.substr(0, 5) === 'laesc') {
      return `hw${viewerSeries.substr(-4, 4)}`;
    }
    if (viewerSeries.substr(0, 5) === 'wordh') {
      return `wh${viewerSeries.substr(-4, 4)}`;
    }
    if (viewerSeries.substr(0, 3) === 'jiw') {
      return `jump${viewerSeries.substr(-4, 4)}`;
    }
    return viewerSeries;
  }

  getViewerEngineFromComponentId(productLineId: string, componentId: string): string {
    const productType = this.getProductTypeFromProductLine(productLineId);
    const engineMatches = componentId.match(/_(G[PK0-9-]{1,}[MCLION]{0,1})_([A-Z-]+(2[MC])?)$/i);

    if (engineMatches && engineMatches.length > 2) {
      const engine = engineMatches[2].toLocaleLowerCase();

      if (productType === ProductType.jiw2021n) {
        const suffix = engineMatches[1].toLocaleLowerCase().substr(-1, 1);
        if (suffix === 'i') {
          return `info_${engine}`;
        }
        if (suffix === 'o') {
          return `opin_${engine}`;
        }
        if (suffix === 'n') {
          return `narr_${engine}`;
        }
      }
      // for Catholic Practice Master need to add grade suffix to engine for second grade
      if (engine.includes('catholicpm') && engineMatches[1].includes('2')) {
        return `${engine}${engineMatches[1].toLocaleLowerCase().substring(1, 3)}`;
      }
      return engine;
    }
    return null;
  }

  /** Gets the thumbnail location from the product and viewer asset. Use this for supplemental products */
  getViewerThumbnail(asset: IViewerAsset, productLineId: string, componentId: string, variantType: string): string {
    const productLine = this.getViewerSeriesDesignation(this.getViewerSeries(productLineId));
    const gradeDesignation = this.getGradeDesignationFromComponentId(componentId);
    const variantDir = this.getVariantDir(productLine, variantType, gradeDesignation);
    const langCode = (asset.language === ViewerLanguage.English || asset.language === ViewerLanguage.en)
      ? ViewerLanguage.en : ViewerLanguage.es;
    const languagePath = productLine.includes('hw') ? `${langCode}/` : '';
    return this.getContentManifestUrl(`${variantDir.toLocaleLowerCase()}/${Helpers.getProductLineFolder(productLine)}/viewer/thumbs/${languagePath}${asset.assetId}.png`);
  }

  /** Gets the thumbnail for component materials.
   * Use this for Lesson/Material page setups i.e. Superkids etc.*/
  getMaterialThumbnail(componentId: string, productLineKey: string, variantType: string): string {
    let gradeFolder = variantType;
    const productLine = productLineKey.toLocaleLowerCase();
    const productType = ProductType[productLine] || ProductType.None;
    if (
      !Helpers.lessonMaterialPageProductTypes.includes(productType)
      && (gradeFolder === VariantType.Grade2C || gradeFolder === VariantType.Grade2M)) {
      gradeFolder = VariantType.Grade2;
    }
    const path = `content/${gradeFolder.toLocaleLowerCase()}/${Helpers.getProductLineFolder(productLine)}/viewer/thumbs/materials/${componentId}.png`;
    return `${this.appConfig.assetUrl}${path}`;
  }

  getVariantDir(productLineName: string, variantType: string, gradeDesignation: string): string {
    return productLineName.includes('hw') ? `grade${gradeDesignation}` : variantType;
  }

  // Gets grade designation is from componentId, which is the only consistent method to get it.
  getGradeDesignationFromComponentId(componentId: string): string {
    const matches = componentId.match(/G([0-9K]+).?/i);
    if (matches && matches.length > 1) {
      return matches[1];
    }
    return 'none';
  }

  /**
   * Gets the viewer assets either from Api or legacy JSON.
   */
  getViewerAssets(
    productType: ProductType,
    variantType: VariantType | string,
    componentId: string = null,
    type: string = 'viewer-asset',
    classroom: IClassroom = null,
  ): Observable<ApiResponse<IViewerAsset[]>> {
    return this.getViewerAssetsFromApi(productType, variantType, type, classroom, componentId);
  }

  /**
   * Gets the viewer assets from the API.
   */
  getViewerAssetsFromApi(productType: ProductType, variantType: VariantType | string, type: string = 'viewer-asset',
    classroom: IClassroom = null, componentId: string = null): Observable<ApiResponse<IViewerAsset[]>> {
    return this.http.get<ApiResponse<IViewerAsset[]>>(
      this.getViewerAssetsUrl(productType, variantType, type, classroom))
      .pipe(
        map(res => new ApiResponse<IViewerAsset[]>(true, {
        // Supplemental, non-Material pages, split assets by product.
          response: (componentId ? res.response.filter(a => a.componentId === componentId)
            .map(item => ({
              ...item,
              language: this.getNormalizedLanguage(item.language),
            })) : [...res.response]),
          messages: [...res.messages],
        })),
      );
  }

  /**
   * Gets the absolute URL of a downloadable asset.
   */
  getDownloadableViewerAssetUrl(productLineKey: string, productComponentId: string, asset: IViewerAsset, variantType: string = null): string {
    const series = this.getViewerSeries(productLineKey);
    const gradeDesignation = this.getGradeDesignationFromComponentId(productComponentId);
    const variantDir = this.getVariantDir(productLineKey, variantType, gradeDesignation);

    return `${this.appConfig.assetUrl}content/${variantDir.toLocaleLowerCase()}/${series}/viewer/downloads/${asset.fileName}`;
  }

  getTopicsByProductAndGrade(productType: ProductType, grade: string, classroomId: string, isLongPoll: boolean = false): Observable<TopicGroup[]> {
    return this.getTopicsFromApi(productType, grade, classroomId, isLongPoll)
      .pipe(
        // Map topic groups by unit instead of topic ordinal for Handwriting.
        map((topicGroups: TopicGroup[]) => (this.isHandwriting(productType)
          ? this.mapTopicGroupsByUnit(topicGroups)
          : topicGroups
        ))
      );
  }

  getTopicsByProductAndGradePaging(productType: ProductType, grade: string, classroomId: string, selectedUnit?: number): Observable<TopicGroup[]> {
    return this.getTopicsFromPagingApi(productType, grade, classroomId, selectedUnit)
      .pipe(
        // Map topic groups by unit instead of topic ordinal for Handwriting.
        map((topicGroups: TopicGroup[]) => (this.isHandwriting(productType)
          ? this.mapTopicGroupsByUnit(topicGroups)
          : topicGroups
        ))
      );
  }

  mapTopicGroupsByUnit(topicGroups: TopicGroup[]): TopicGroup[] {
    const combined: TopicGroup[] = [];
    topicGroups.forEach((topicGroup) => {
      const index = combined.findIndex(tg => tg.items[0].unit === topicGroup.items[0].unit);
      if (index === -1) {
        combined.push(topicGroup);
      } else {
        // Combines the topicGroup into the previous one.
        combined[index].items = combined[index].items.concat(topicGroup.items);
      }
    });
    return combined;
  }

  mapTopicGroupValues(group: TopicGroup): TopicGroup {
    const items = group.items || group.lessons;
    // Sets topic group isLocked when an item (the first) is locked since topics are locked by unit, not topic.
    const lockedItem = items.find(t => t.isLocked === true && t.starEarned !== true);
    let unit = group.unit ?? null;
    if (unit == null) {
      const matches = items[0].topicKey.match(/U(\d+)/);
      unit = (matches && matches.length > 1) ? Number.parseInt(matches[1], 10) : null;
    }
    return {
      unit,
      unitWeight: group.unitWeight ?? null,
      unitName: group.unitName || null,
      topicName: group.topicName || null,
      firstTopicKeySuffix: items[0]?.topicKeySuffix || null,
      grade: GradeType[group.grade] || null,
      isLocked: lockedItem ? lockedItem.isLocked : false,
      items: items.map((topic) => {
        const ret = {
          ...topic,
          unit,
          unitName: topic.unitName || group.unitName,
          unitWeight: topic.unitWeight || group.unitWeight,
        };
        if (topic.isLocked) {
          ret.lockedReason = topic.lockedReason ? topic.lockedReason : this.genericLockedReason;
        } else {
          ret.lockedReason = null;
        }

        return ret;
      }),
    };
  }

  mapTopicGroupPagedValues(group: NavigationResponse, pagedItems: NavigationPagedItemResponse[]): TopicGroup {
    const { items } = group;
    // Sets topic group isLocked when an item (the first) is locked since topics are locked by unit, not topic.
    const lockedItem = items.find(t => t.isLocked === true && t.starEarned !== true);
    let unit = group.unit ?? null;
    if (unit == null) {
      const matches = items[0].topicKey.match(/U(\d+)/);
      unit = (matches && matches.length > 1) ? Number.parseInt(matches[1], 10) : null;
    }

    return {
      unit,
      unitWeight: items[0]?.unitWeight ?? null,
      unitName: items[0]?.unitName || null,
      topicName: group.topicName || null,
      firstTopicKeySuffix: items[0]?.topicKeySuffix || null,
      grade: GradeType[items[0]?.grade] || null,
      isLocked: lockedItem ? lockedItem.isLocked : false,
      pagedItems: pagedItems ?? null,
      items: items.map((topic) => {
        const ret = {
          ...topic,
          unit,
          unitName: topic.unitName ||  items[0]?.unitName,
          unitWeight: topic.unitWeight ||  items[0]?.unitWeight,
        };
        if (topic.isLocked) {
          ret.lockedReason = topic.lockedReason ? topic.lockedReason : this.genericLockedReason;
        } else {
          ret.lockedReason = null;
        }

        return ret;
      }),
    };
  }

  private getTopicsFromApi(productType: ProductType, grade: string, classroomId: string, isLongPoll: boolean = false): Observable<TopicGroup[]> {
    // Set long polling header to turn off portal interceptor loading spinner
    const requestOptions = isLongPoll ? { headers: new HttpHeaders({ 'zbportal-long-polling': 'true' }) } : {};

    return this.http.get<ApiResponse<TopicGroup[]>>(
      this.getTopicsApiUrl(productType, grade, classroomId), requestOptions
    )
      .pipe(
        map((res: ApiResponse<TopicGroup[]>) => res.response.map((g: TopicGroup) => this.mapTopicGroupValues(g)))
      );
  }


  private getTopicsFromPagingApi(productType: ProductType, grade: string, classroomId: string, selectedUnit?: number): Observable<TopicGroup[]> {
    const data = {
      selectedUnit
    };
    return this.http.patch<ApiResponse<NavigationPagedResponse>>(
      this.getTopicsApiUrl(productType, grade, classroomId), data
    )
      .pipe(
        map((res: ApiResponse<NavigationPagedResponse>) => (
          res.response.navigationItems.map(g => this.mapTopicGroupPagedValues(g, res.response.pagedItems))))
      );
  }

  getLeaderboardForClassroom(classroomId: string, productType: ProductType = ProductType.gum2021n): Observable<ApiResponse<StudentRank[]>> {
    return this.http.get(this.getLeaderboardUrl(classroomId, productType))
      .pipe(
        map((res: ApiResponse<StudentRank[]>) => new ApiResponse<StudentRank[]>(true, res)),
      );
  }

  getProductUrlForStudentActivity(classroomId: string,
    activityId: string,
    topicKey: string,
    productType: ProductType,
    grade: GradeType | string,
    // These are both models for "licenses". Whoever did this originally should be held responsible.
    licenses: (IClassroomProductDescription | SchoolLicense)[]): Observable<string> {
    const componentId = this.getComponentIdFromProductType(productType, grade);
    const params = `componentId=${componentId}&classroomId=${classroomId}&activity=${topicKey}&activityId=${activityId}`;

    const license = licenses.find((l: IClassroomProductDescription | SchoolLicense) => {
      if (l instanceof IClassroomProductDescription) {
        return l.productType === productType
          && Helpers.mapVariantToGradeType(l.variantType) === grade;
      }
      if (l instanceof SchoolLicense) {
        return l.productType === productType
          && Helpers.mapVariantToGradeType(l.variant) === grade;
      }
      return null;
    });

    if (license || this.userService.isAuthorizedForStudentActivity()) {
      return of(`${window.location.origin}/sites/digital-resources/?${params}`);
    }

    return of(null);
  }

  getProductProfileKey(productType: ProductType): string {
    switch (productType) {
    case ProductType.hw2020n:
    case ProductType.hw2025n:
    case ProductType.hw2020tx:
    case ProductType.hw2020txs:
    case ProductType.laesc2020n:
    case ProductType.laesc2020tx:
    case ProductType.laesc2020txs:
      return 'handwritingProfiles';
    case ProductType.gum2021n:
      return 'gumProfiles';
    case ProductType.spcn2016n:
    case ProductType.spcn2020tx:
    case ProductType.spcn2020txs:
    case ProductType.spcn2022:
      return 'spellingProfiles';
    case ProductType.sk2015:
    case ProductType.sk2017:
    case ProductType.sk2026:
    case ProductType.fsk2021:
      return 'superkidsProfiles';
    default:
      throw new Error('Invalid product type for product profile');
    }
  }

  isHandwriting(productType: ProductType): boolean {
    return this.handwritingProducts.includes(productType);
  }

  isSuperkids(productType: ProductType): boolean {
    return this.superkidsProducts.includes(productType);
  }

  /**
   * Checks whether a product component is considered a "Material" for Materials pages.
   *
   * @param {IProductComponent} productComponent The product component i.e. launchable thing.
   */
  isMaterialComponent(productComponent: IProductComponent): boolean {
    const launchTypes = [...this.materialLaunchTypes];
    const productType = this.getProductTypeFromProductLine(productComponent.componentId);

    // We can use componentId for the ProductLine check because they're consistent.
    if (this.isHandwriting(productType)) {
      // GumLessons should appear on Materials page, but only for Handwriting.
      launchTypes.push(LaunchType.GumLessons);
    }
    if (Helpers.lessonMaterialPageBookProductTypes.includes(productType)) {
      // Teacher guide should appear on Materials page, but only for Mindscapes & hw2025.
      launchTypes.push(LaunchType.eBook);
    }

    return launchTypes.includes(productComponent.launchType);
  }

  getProductTypeFromProductLine(productKey: string): ProductType {
    const matches = productKey.match(/^([a-z]+[0-9]+)([a-z]{0,})/i);
    if (matches) {
      const productLineKey = matches[0].toLocaleLowerCase();
      return ProductType[productLineKey] ? ProductType[productLineKey] : ProductType.None;
    }
    return ProductType.None;
  }

  /**
   * Extracts the product line from the lesson card topic key.
   *
   * @param {string} cardTopicKey The lesson card topic key, which is NOT considered a ZBPortal {Topic}.
   *
   * @returns {string|null}
   */
  getProductLineFromLessonCardTopicKey(cardTopicKey: string): string {
    const matches = cardTopicKey.match(/^([a-z]+[0-9]+)([a-z]{0,})/i);
    if (matches) {
      return matches[0].toLocaleUpperCase();
    }
    return null;
  }

  private getComponentIdFromProductType(productType: ProductType, gradeType: GradeType | string): string {
    // This is the best the front-end can do to figure out how to launch a component from a quest because a quest is
    // not related to a product component.
    const productTypePrefix: string = Object.keys(ProductType).find(key => ProductType[key] === productType);
    const grade: string = Helpers.mapGradeTypeToGrade(gradeType);
    const suffix: string = this.getQuestComponentSuffix(productType);
    return `${productTypePrefix.toLocaleUpperCase()}_G${grade}_${suffix}`;
  }

  private getQuestComponentSuffix(productType: ProductType): string {
    switch (productType) {
    case ProductType.hw2020n:
    case ProductType.hw2025n:
    case ProductType.hw2020tx:
    case ProductType.hw2020txs:
    case ProductType.laesc2020n:
    case ProductType.laesc2020tx:
    case ProductType.laesc2020txs:
      return 'HQ';
    case ProductType.gum2021n:
      return 'GQ';
    case ProductType.spcn2020tx:
    case ProductType.spcn2020txs:
    case ProductType.spcn2022:
      return 'SQ';
    case ProductType.spcn2016n:
      return 'OG';
    case ProductType.fsk2021:
    case ProductType.sk2015:
    case ProductType.sk2017:
    case ProductType.sk2026:
      return 'SKOF';
    default:
      throw new Error('Invalid product type for quests');
    }
  }

  /**
   * Gets a product line from product lines by product line key.
   *
   * @param {IProductLine[]} productLines an array of one or more productlines from product navigation.
   * @param {string} productLineKey the product line key to extract the productline from.
   *
   * @returns {IProductLine}
   */
  getProductLineByKey(productLines: IProductLine[], productLineKey: string): IProductLine {
    return productLines.find(p => p.productLineKey === productLineKey);
  }

  /**
   * Gets a product variant from product lines.
   *
   * @param {IProductLine[]} productLines an array of one or more productlines from product navigation.
   * @param {string} productLineKey the product line key to extract the productline from.
   * @param {string} variantType the variant type / key.
   *
   * @returns {IProductVariant}
   */
  getProductLineVariant(
    productLines: IProductLine[],
    productLineKey: string,
    variantType: string): IProductVariant {
    return this.getProductLineByKey(productLines, productLineKey).variants.find(v => v.variantType === variantType);
  }

  /**
   * Gets a product component inside productLines.
   *
   * @param {IProductLine[]} productLines an array of one or more productlines from product navigation.
   * @param {string} productLineKey the product line key to extract the productline from.
   * @param {string} variantType the variant type / key.
   * @param {string} productId the product component product identifier.
   *
   * @returns {IProductComponent}
   */
  getProductComponentById(
    productLines: IProductLine[],
    productLineKey: string,
    variantType: string,
    productId: string): IProductComponent {
    return this.getProductLineVariant(productLines, productLineKey, variantType)
      .skus.reduce((component: IProductComponent, sku: IProduct): IProductComponent => (!component
        ? sku.components.find(c => c.productId === productId)
        : component),
      null);
  }

  /**
   * Gets a product component inside productLines.
   *
   * @todo this should use the ngrx store instead, but ECE.
   *
   * @param {IProductLine[]} productLines an array of one or more productlines from product navigation.
   * @param {string} productLineKey the product line key to extract the productline from.
   * @param {string} variantType the variant type / key.
   * @param {string} componentId the product component identifier to search for inside a SKU.
   *
   * @returns {IProductComponent}
   */
  getProductComponentByComponentId(
    productLines: IProductLine[],
    productLineKey: string,
    variantType: string,
    componentId: string): IProductComponent {
    return this.getProductLineVariant(productLines, productLineKey, variantType)
      .skus.reduce((component: IProductComponent, sku: IProduct): IProductComponent => (!component
        ? sku.components.find(c => c.componentId === componentId)
        : component),
      null);
  }
}
