import { SchoolLicense } from '@models/license/school-license';
import { IProductLine } from '@models/product-line';
import { Quest } from '@models/quest';
import { QuestCollection } from '@models/quest-collection';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GradeType } from '@shared/enums/grade-type';
import { LaunchType } from '@shared/enums/launch-type';
import { LicenseAssignmentType } from '@shared/enums/license-status';
import { ProductType } from '@shared/enums/product-type';
import * as fromLearning from './learning.reducer';

export const selectLearningState = createFeatureSelector<fromLearning.LearningState>(fromLearning.learningFeatureKey);
export const selectProductLines = createSelector(selectLearningState, state => state.productLines);
export const selectClassrooms = createSelector(selectLearningState, state => state?.classrooms);
export const selectClassroomIcons = createSelector(selectLearningState, state => state.classroomIcons);
export const selectSchoolLicenses = createSelector(selectLearningState, state => state.schoolLicenses);
export const selectErrors = createSelector(selectLearningState, state => state.errors);
export const selectClassroomContext = createSelector(selectLearningState, state => state?.classroomContext);
export const selectStudentQuests = createSelector(selectLearningState, state => (state ? state.studentQuests : []));
export const selectClassroom = createSelector(selectClassroomContext, state => (state ? state.classroom : null));
export const selectClassroomStudents = createSelector(selectClassroomContext, state => (state ? state.students : null));
export const selectClassroomLicenses = createSelector(selectClassroomContext, state => (state ? state.licenses : null));
export const selectProductLinesUnloaded = createSelector(selectLearningState, state => !state.productsLoaded);
export const selectClassroomsUnloaded = createSelector(selectLearningState, state => !state.classroomsLoaded);
export const selectClassroomIconsUnloaded = createSelector(selectLearningState, state => !state.classroomIconsLoaded);
export const selectSchoolLicensesUnloaded = createSelector(selectLearningState, state => !state.licensesLoaded);
export const selectClassroomSaved = createSelector(selectLearningState, state => state.classroomSaved);
export const selectLearningInitialRedirectState = createSelector(selectLearningState, (state) => {
  if (state.classroomsLoaded && state.productsLoaded) {
    return (state.productLines && state.productLines.length > 0) || (state.classrooms && state.classrooms.length > 0);
  }
  return null;
});
export const selectClassroomPins = createSelector(selectLearningState, state => (state ? state.classroomPins : null));
export const selectPinState = createSelector(selectLearningState,
  state => (state ? state.pinState : false));

// Product-related selectors.

export const selectProductLine = (productLineKey: string) => (createSelector(selectProductLines,
  (state: IProductLine[]) => (state ? state.find(pl => pl.productLineKey === productLineKey) : null)));

// Quest-related selectors.
export const selectStudentQuestsFromProductClassroom = (
  productType: ProductType,
  classroomId: string,
  gradeType: GradeType | string,
) => (createSelector(selectStudentQuests, (state: QuestCollection[]): Quest[] => {
  if (state) {
    return state
      .filter(c => c.product === ProductType.None || (c.product === productType && c.classroomId === classroomId))
      .reduce((quests: Quest[], collection: QuestCollection) => quests.concat(collection.quests), [])
      .filter((quest: Quest) => {
        if (quest.productType === ProductType.None) {
          return true;
        }

        if (quest.classroomId === classroomId) {
          // UnknownGrade references a random quest.
          return quest.grade === gradeType || quest.grade === GradeType.UnknownGrade;
        }

        return false;
      });
  }
  return [];
}));

export const selectQuestLicensesFromClassroom = (includeRestricted: boolean = false) => (
  createSelector(selectLearningState, (state): SchoolLicense[] => {
    if (state && state.classroomContext && state.classroomContext.licenses && state.productLines) {
      let availableSkuIds: string[] = [];
      state.productLines.forEach((productLine) => {
        if (!includeRestricted && productLine.productLineKey === 'FSK2021') {
          return;
        }
        productLine.variants.forEach((variant) => {
          // Filter SKUs to those that have a component that allows quests.
          const types = [LaunchType.GumLessons, LaunchType.StudentLessons, LaunchType.SuperKidsOnlineFun];
          const skuIds = variant.skus
            .filter(sku => sku.licenseAssignmentType === LicenseAssignmentType.Classroom
              && sku.components.findIndex(component => types.includes(component.launchType)) !== -1)
            .map(sku => sku.skuId);
          availableSkuIds = availableSkuIds.concat(skuIds);
        });
      });

      return state.classroomContext.licenses.filter(l => availableSkuIds.includes(l.skuId));
    }

    return [];
  })
);

export const selectViewerAssets = createSelector(selectLearningState, state => state?.viewerAssets);
