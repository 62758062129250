<!-- When spinner is showing, button type is switched to 'button' to prevent multiple form submissions -->
<button
  #buttonComponent
  class="theme-button"
  [ngClass]="[theme, fontSizeUnit]"
  [class.min-width]="!!useMinWidth"
  [type]="!showSpinner ? type : 'button'"
  [disabled]="disabled"
  [class.has-spinner]="showSpinner"
  [class.full-width]="fullWidth"
  [attr.id]="internalId ? internalId : null"
  [attr.aria-label]="ariaLabel ? ariaLabel : null"
  (click)="handleClick($event)"
  [ngbTooltip]="buttonTooltip"
  [tooltipClass]="tooltipClass"
  [disableTooltip]="!tooltip"
  [autoClose]="true"
  [class]="customClass ?? ''"
  [class.extra-top-bottom-padding]="topBottomPaddingSpacingS"
  [class.remove-pointer-events]="removePointerEvents"
  container="body">

  <div
    [hidden]="showSpinner"
    [class.theme-button-with-icon]="!!iconName"
  >
    <ng-template [ngTemplateOutlet]="iconPosition == 'left'
                                     ? ZbpButtonIcon : undefined">
    </ng-template>
    {{label}}
    <ng-template [ngTemplateOutlet]="iconPosition == 'right'
                                     ? ZbpButtonIcon : undefined">
    </ng-template>
  </div>
  <img
    src="/assets/img/Spin-1s-100px.svg"
    alt="Spinner"
    class="button-spinner"
    [hidden]="!showSpinner"
  />
</button>
<ng-template #ZbpButtonIcon>
  <zbp-icon *ngIf="!!iconName"
            [iconName]="iconName"
            [iconFillColor]="iconFillColor"
            [iconSize]="iconSize">
  </zbp-icon>
</ng-template>
<ng-template #buttonTooltip>
  <span id="iconTooltipContent">{{tooltip}}</span>
</ng-template>
