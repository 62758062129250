<div *ngIf="!isLoading" [class]="brandClass">
  <nav *ngIf="!isHighlights" class="role-icon-container">
    <a
      *ngFor="let button of loginRoleTypes"
      [routerLink]="button.route"
      [id]="button.id"
      [attr.aria-labelledby]="button.id + '-label'"
      routerLinkActive="role-link-active"
      class="role-link">
      <zbp-role-button [iconName]="button.zbpIconName"></zbp-role-button>
      <span [id]="button.id + '-label'" class="role-label">{{button.subtitle}}</span>
    </a>
  </nav>
  <header class="row mt-auto">
    <h1 id="pageTitle" class="h1 text-center">{{welcomeText}}</h1>
    <h2 id="titleSubtext" class="h2 text-center">{{subtitleText}}</h2>
  </header>
  <form id="loginForm" class="w-100 login-form" [class.px-5]="isHighlights" [formGroup]="loginForm" (ngSubmit)="login()">
    <aside *ngIf="errorMessage"
      class="row d-block mx-auto w-100 alert alert-danger fw-bold text-uppercase text-center">
      <i class="w-auto px-0 fa fa-circle-exclamation text-danger"></i>
      <span class="w-auto ps-1">{{ errorMessage }}</span>
    </aside>
    <div class="row input-group mx-auto w-100">
      <label for="username" class="form-label form-label-hfc" [class.label-error]="(userNameError$ | async)">Email</label>
      <input id="username" class="form-control form-control-hfc" type="email" spellcheck="false" tabindex="0"
        formControlName="userName" [class.is-invalid]="(userNameError$ | async)" data-cy="login-username">
      <div id="usernameFeedback" class="invalid-feedback text-uppercase" [class.d-block]="(userNameError$ | async)">
        Email required
      </div>
    </div>
    <div class="row input-group mx-auto w-100">
      <label for="password" class="form-label form-label-hfc" [class.label-error]="(passwordError$ | async)">Password</label>
      <div class="loginPasswordGroup m-0 p-0">
        <input id="password" class="form-control form-control-hfc" [type]="showPassword ? 'text' : 'password'"
          autocomplete="off" spellcheck="false" tabindex="0" formControlName="password"
          [class.is-invalid]="(passwordError$ | async)" data-cy="login-password">
        <button id="showPasswordButton" class="p-0 m-0 h-100" type="button"
          [class.is-invalid]="(passwordError$ | async)" [attr.aria-label]="showPassword ? 'Hide password' : 'Show password' "
          (click)="toggleShowPassword()">
          <i class="fa" [class.fa-eye-slash]="!showPassword" [class.fa-eye]="showPassword"></i>
        </button>
      </div>
      <div id="passwordFeedback" class="invalid-feedback text-uppercase" [class.d-block]="(passwordError$ | async)">
        Password required
      </div>
    </div>
    <div class="row d-flex flex-column mx-auto w-100">
      <zbp-button-component
        id="loginButton"
        type="submit"
        theme="text"
        label="Log In"
        theme="primary"
        data-cy="login-submit-button"
        class="login-button"
        [showSpinner]="loginProcessing"
        [fullWidth]="true"
        [topBottomPaddingSpacingS]="true"
        >
      </zbp-button-component>
      
      <a id="forgotPasswordButton"
         class="red-text-link"
         href="/login/forgot-password">
        Forgot your password?
      </a>
    </div>
  </form>
  <zbp-sso-form *ngIf="!isHighlights"></zbp-sso-form>
</div>
