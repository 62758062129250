<section *ngIf="roleTypeToAdd" class="create-user-form-container" [formGroup]="form" class="d-flex flex-column">
  <!-- Password Requirement Tooltip List -->
  <ng-template #passwordToolTipContent>
    <div class="text-start">
      <span>Passwords must:</span>
      <ul>
        <li *ngFor="let passwordRequirement of passwordRequirements">{{ passwordRequirement }}</li>
      </ul>
    </div>
  </ng-template>

  <div *ngIf="newUsers?.controls" formArrayName="newUsers" id="createSchoolUsersRows" class="w-100">
    <div *ngFor="let uf of newUsers.controls; index as i" [formGroupName]="i" class="newUserRow">
      <div class="newUserRowFormFields">
        <div class="form-group form-input" [class.parent]="isAddingParent">
          <label class="form-label mb-0" for="newUser{{i}}FirstName" [class.error]="formControlInvalidAndTouched(i, formFieldNames.firstName.name)">
            {{formFieldNames.firstName.label}}
            <span aria-hidden="true">*</span>
          </label>
          <input id="newUser{{i}}FirstName" type="text" class="form-control" required [formControlName]="formFieldNames.firstName.name" [class.is-invalid]="formControlInvalidAndTouched(i, formFieldNames.firstName.name)">
          <div class="errorWrapper">
            <span *ngIf="formControlInvalidAndTouched(i, formFieldNames.firstName.name)" class="errorText error">{{nameFieldErrorText(i, formFieldNames.firstName.name)}}</span>
          </div>
        </div>
        <div class="form-group form-input" [class.parent]="isAddingParent">
          <label class="form-label mb-0" for="newUser{{i}}LastName" [class.error]="formControlInvalidAndTouched(i, formFieldNames.lastName.name)">
            {{formFieldNames.lastName.label}}
            <span aria-hidden="true">*</span>
          </label>
          <input id="newUser{{i}}LastName" type="text" class="form-control" required [formControlName]="formFieldNames.lastName.name" [class.is-invalid]="formControlInvalidAndTouched(i, formFieldNames.lastName.name)">
          <div class="errorWrapper">
            <span *ngIf="formControlInvalidAndTouched(i, formFieldNames.lastName.name)" class="errorText error">{{nameFieldErrorText(i, formFieldNames.lastName.name)}}</span>
          </div>
        </div>
        <div *ngIf="hasPasswordFields" class="form-group form-input">
          <label class="form-label mb-0" for="newUser{{i}}ExternalId">
            External ID
            <button tabindex="-1"
              id="newUser{{i}}ExternalIdTooltipButton"
              type="button"
              class="btn btn-xs"
              aria-label="Topic locking help"
              ngbPopover="Optionally provide the system identifier for this student from your Student Information System or Learning Management System."
              container="body"
              placement="top">
              <i class="fa-regular fa-question-circle" aria-hidden="true"></i>
            </button>
          </label>
          <input
            id="newUser{{i}}ExternalId"
            type="text"
            class="form-control no-error-placeholder"
            formControlName="externalId">
      </div>

        <!-- Student Username Field -->
        <div *ngIf="isAddingStudent" class="form-group form-input">
            <label class="form-label mb-0" for="newUser{{i}}UserName" [class.error]="formControlInvalidAndTouched(i, formFieldNames.username.name)">
            {{formFieldNames.username.label}}
            <span aria-hidden="true">*</span>
            <button tabindex="-1"
            id="newUser{{i}}UserNameTooltipButton"
            type="button"
            class="btn btn-xs"
            ngbPopover="Provide the user name with which the user will log in."
            container="body"
            placement="top-left">
            <i class="fa-regular fa-question-circle" aria-hidden="true"></i>
          </button>
          </label>
          <input id="newUser{{i}}UserName" type="text" autocomplete="username" class="form-control" required [formControlName]="formFieldNames.username.name" [class.is-invalid]="formControlInvalidAndTouched(i, formFieldNames.username.name)">
          <div class="errorWrapper">
            <span *ngIf="formControlInvalidAndTouched(i, formFieldNames.username.name)" class="errorText error">{{formFieldNames.username.label}} REQUIRED</span>
          </div>
        </div>

        <!-- Non-student Username Fields -->
        <div *ngIf="!isAddingStudent" class="form-group form-input" [class.lastInputGroup]="!hasPasswordFields" [class.parent]="isAddingParent">
          <label class="form-label mb-0" for="newUser{{i}}UserName" [class.error]="formControlInvalidAndTouched(i, formFieldNames.username.name)">
            Email
            <span aria-hidden="true">*</span>
            <i class="fa-regular fa-question-circle" aria-hidden="true" [ngbTooltip]="'Provide the Email with which the user will log in.'"></i>
          </label>
          <input id="newUser{{i}}UserName" type="email" class="form-control" required [formControlName]="formFieldNames.username.name" [class.is-invalid]="formControlInvalidAndTouched(i, formFieldNames.username.name)">
          <div class="errorWrapper">
            <span *ngIf="formControlInvalidAndTouched(i, formFieldNames.username.name)" class="errorText error">Email Required</span>
          </div>
        </div>

        <!-- Password Fields -->
        <ng-container *ngIf="hasPasswordFields">
          <div class="form-group form-input">
            <label class="form-label mb-0" for="newUser{{i}}Password" [class.error]="formControlInvalidAndTouched(i, formFieldNames.password.name)">{{formFieldNames.password.label}}</label>
            <input
              id="newUser{{i}}Password"
              type="password"
              class="form-control"
              autocomplete="new-password"
              [formControlName]="formFieldNames.password.name"
              [class.is-invalid]="formControlInvalidAndTouched(i, formFieldNames.password.name)"
              [ngbPopover]="passwordToolTipContent">
              <div class="errorWrapper">
                <span *ngIf="formControlInvalidAndTouched(i, formFieldNames.password.name)" class="errorText error">Password Invalid</span>
              </div>
          </div>
          <div class="form-group form-input">
            <label class="form-label form-label--confirmPassword  mb-0" for="newUser{{i}}ConfirmPassword" [class.error]="confirmPasswordInvalid(i)">{{formFieldNames.confirmPassword.label}}</label>
            <input
              id="newUser{{i}}ConfirmPassword"
              type="password"
              class="form-control"
              autocomplete="new-password"
              [formControlName]="formFieldNames.confirmPassword.name"
              [class.is-invalid]="confirmPasswordInvalid(i)"
              [ngbPopover]="passwordToolTipContent">
              <div class="errorWrapper">
                <span *ngIf="confirmPasswordInvalid(i)" class="errorText error">{{confirmPasswordFieldErrorText(i)}}</span>
              </div>
          </div>
        </ng-container>

        <!-- Parent Fields -->
        <ng-container *ngIf="hasParentFields">
          <div class="form-group form-input">
            <label class="form-label mb-0" for="newUser{{i}}ParentFirstName" [class.error]="formControlInvalid(i, formFieldNames.parentFirstName.name)">Parent First Name</label>
            <input id="newUser{{i}}ParentFirstName" type="text" class="form-control no-error-placeholder" [formControlName]="formFieldNames.parentFirstName.name" [class.is-invalid]="formControlInvalid(i, formFieldNames.parentFirstName.name)">
            <div class="errorWrapper">
              <span *ngIf="formControlInvalid(i, formFieldNames.parentFirstName.name)" class="errorText error">{{nameFieldErrorText(i, formFieldNames.parentFirstName.name)}}</span>
            </div>
          </div>
          <div class="form-group form-input">
            <label class="form-label mb-0" for="newUser{{i}}ParentLastName" [class.error]="formControlInvalid(i, formFieldNames.parentLastName.name)">Parent Last Name</label>
            <input id="newUser{{i}}ParentLastName" type="text" class="form-control no-error-placeholder" [formControlName]="formFieldNames.parentLastName.name" [class.is-invalid]="formControlInvalid(i, formFieldNames.parentLastName.name)">
            <div class="errorWrapper">
              <span *ngIf="formControlInvalid(i, formFieldNames.parentLastName.name)" class="errorText error">{{nameFieldErrorText(i, formFieldNames.parentLastName.name)}}</span>
            </div>
          </div>
          <div class="form-group form-input lastInputGroup">
            <label class="form-label mb-0" for="newUser{{i}}ParentUserName" [class.error]="formControlInvalid(i, formFieldNames.parentUsername.name)">Parent Email</label>
            <input id="newUser{{i}}ParentUserName" type="email" class="form-control no-error-placeholder" [formControlName]="formFieldNames.parentUsername.name" [class.is-invalid]="formControlInvalid(i, formFieldNames.parentUsername.name)">
            <div class="errorWrapper">
              <span *ngIf="formControlInvalid(i, formFieldNames.parentUsername.name)" class="errorText error">{{nameFieldErrorText(i, formFieldNames.parentUsername.name)}}</span>
            </div>
          </div>
        </ng-container>
        <div *ngIf="isAddingParent" class="form-group form-input lastInputGroup parent">
          <label for="studentUsername{{ i }}" class="form-label mb-0" [class.error]="studentFormControlInvalid(getStudentFormControl(i))">
            Student
            <span aria-hidden="true">*</span>
          </label>
          <zbp-user-search-with-dropdown
            [i]="i"
            [school]="school"
            [role]="RoleType.Student"
            [control]="getStudentFormControl(i)"
            [isInvalid]="studentFormControlInvalid"
          >
          </zbp-user-search-with-dropdown>
          <div class="errorWrapper">
            <span *ngIf="studentFormControlInvalid(getStudentFormControl(i))" class="errorText error">{{nameFieldErrorText(i, formFieldNames.student.name)}}</span>
          </div>
        </div>
      </div>
      <div class="align-items-baseline text-end remove-button-container">
        <button *ngIf="!hideFirstRowDeleteButton ? true : i !== 0" id="removeRow{{i}}" class="btn btn-sm removeButton" type="button" [attr.aria-label]="'Remove User'" (click)="removeRow(i)">
          <i class="fa-regular fa-circle-xmark"></i>
        </button>
      </div>
    </div>
    <div class="d-flex">
      <zbp-button-component
        *ngIf="canAddMultipleUsers"
        [iconName]="zbpIconNames.t_cross"
        iconSize="font-size"
        theme="primaryText"
        [label]="addAnotherUserLabel"
        iconPosition="left"
        class="addAnotherButton"
        (clickHandler)="addAnother()"
        [disabled]="newUsers?.length >= maxRequired"
      >
      </zbp-button-component>
      <div *ngIf="canUserSearch" class="userSearch">
        <div class="orSeparator">OR</div>
        <zbp-user-search-with-dropdown
          class="addSearchBox"
          [i]="0"
          [school]="school"
          [role]="roleTypeToAdd"
          [control]="searchBox"
          [itemSelectionFunction]="userSearchSelectionFunction"
          [pageSize]="10"
          [activeOnly]="true"
          [placeholderText]="'Search Existing'"
          [isDisabled]="newUsers?.length >= maxRequired"
        >
        </zbp-user-search-with-dropdown>
      </div>
    </div>
    <div class="text-uppercase requiredText">* Required</div>
  </div>
</section>
