<main id="brandedAccountSetupComponent" 
  [class]="containerClass"
  *ngIf="!isLoading; else preloader"
  [class.submitActionResponseDisplaying]="isTokenInvalid"
  [class.link-expired]="!isLoading && isTokenInvalid && !formSubmitted"
  class="w-100">
  <zbp-back-to-website-link *ngIf="!isHighlights" class="fixed-position-top-left"></zbp-back-to-website-link>
  <figure id="accountSetupLogoContainer" class="text-center account-setup-logo-container container-section">
    <img 
      class='account-setup-brand-logo'
      width="230"
      height="121"
      [ngSrc]="logoSrc"
      [alt]="logoAltText"
      priority
    />
  </figure>
  <img *ngIf="isLoading"
       ngSrc="/assets/img/Spinner-1s-200px.svg"
       alt="the page is loading"
       width="200"
       height="200"
       priority
  />

  <!-- Valid Token Section -->
  <ng-container *ngIf="!isTokenInvalid && !formSubmitted"
                id="highlightsActivateSection">
    <h1 class="pageTitle text-center">
      {{ titleText }}
    </h1>

    <!-- Account Setup Section for User Info (Used by Account Setup and not Password Reset) -->
    <div *ngIf="inAccountSetup && user"
         id="userInformation"
         class="user-info-container mx-auto my-2 w-50">
      <div class="sectionSubTitle">
        <h2 class="h3 account-section-sub-title">User Information</h2>
      </div>
      <div class="user-info-field-container">
        <label for="firstName"
               class="form-label">
          First Name
        </label>
        <div id="firstName">
          {{user.firstName}}
        </div>
      </div>
      <div class="user-info-field-container">
        <label for="lastName"
               class="form-label">
          Last Name
        </label>
        <div id="lastName">
          {{user.lastName}}
        </div>
      </div>
      <div class="user-info-field-container">
        <label for="email"
               class="form-label ">
          Email Address
        </label>
        <div id="email">
          {{user.username}}
        </div>
      </div>
    </div>

    <!-- Password Section -->
    <form id="accountSetupForm"
          [formGroup]="form"
          class="mx-auto my-2 account-setup-form password-reset-flow-form"
          (ngSubmit)="submitForm()">
      <div *ngIf="inAccountSetup" class="sectionSubTitle">
        <h2 class="h3 account-section-sub-title">Set Password</h2>
      </div>

      <!-- Current Password is added when there is a userId set in UserService for Account Setup Forms -->
      <div *ngIf="form.controls.currentPassword"
           class="row input-group mx-auto my-2 w-100">
        <label for="currentPassword" class="form-label ">
          Current password
        </label>
        <div class="account-password-group m-0 p-0">
          <input id="currentPassword"
                 [type]="showCurrentPassword ? 'text' : 'password'"
                 name="currentPassword"
                 required
                 class="form-control"
                 autocomplete="off"
                 formControlName="currentPassword"
                 [class.is-invalid]="!isControlValid('currentPassword')"
          />
          <button id="showCurrentPasswordButton"
                  class="p-0 m-0 h-100 show-password-button"
                  type="button"
                  [class.is-invalid]="!isControlValid('currentPassword')"
                  [attr.aria-label]="'Show password'"
                  (click)="toggleShowCurrentPassword()">
            <i class="fa"
               [class.fa-eye-slash]="!showCurrentPassword"
               [class.fa-eye]="showCurrentPassword">
            </i>
          </button>
        </div>
      </div>
      
    <!-- Password Requirements for Reset Password -->
    <!-- Reset Password components are aligned to left of page in Account Setup -->
    <zbp-password-requirements
      [form]="form"
      [apiResponseMessages]="passwordApiMessages"
      [centerRequirements]="!inAccountSetup"
    >
    </zbp-password-requirements>
    <div class="row d-flex flex-column mx-auto form-control-button-hfc">
      <zbp-button-component
        id="activateSubmit"
        type="submit"
        [disabled]="!isSaveEnabled()"
        label="Save"
        theme="primary"
        [fullWidth]="true"
        [topBottomPaddingSpacingS]="true"
        customClass="save-reset-save-button"
        [showSpinner]="isSaving">
      </zbp-button-component>
    </div>
  </form>

    <!-- Account Setup notes for HL Portal -->
    <aside *ngIf="isHighlights && inAccountSetup" id="passwordInstructions"
           class="mx-auto text-center">
      Your administrators set your current contact information.
    </aside>
  </ng-container>

  <!-- Successful save -->
  <section *ngIf="successfulSave && formSubmitted">
    <zbp-login-information-cta
      [zbpIconName]="iconNames.check_circle_inverted"
      iconAlt="Succes Icon"
      class="setup-submit-action-response"
      headingText="Password reset successful"
      bodyHTML="You have successfully reset your password."
      [showPrimaryButton]="false"
      [showBackToLoginLinkButton]="true"
    ></zbp-login-information-cta>
  </section>

  <!-- Invalid Token Screen -->
  <section class="container-section" *ngIf="!isLoading && isTokenInvalid && !formSubmitted">
    <zbp-alert-bar-container>
      <p>Your link has expired for security reasons. Please request a new link using</p>
        <a (click)="goForgot()">
          Forgot your password?
        </a>
    </zbp-alert-bar-container>
  </section>

  <zbp-footer class="footer container-section"></zbp-footer>
</main>

<ng-template #preloader>
  <main class='setup-reset-preloader-container'>
    <zbp-preloader ></zbp-preloader>
  </main>
</ng-template>
