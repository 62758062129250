import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AppConfigService } from '@core/appconfig.service';
import { AuthenticationService } from '@core/authentication.service';
import { ButtonComponent } from '@shared/button/button.component';
import { CompanyCode } from '@shared/enums/company-code';
import { PreloaderModule } from '@shared/preloader/preloader.module';
import { SharedModule } from '@shared/shared.module';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subscription, filter, map } from 'rxjs';

interface ResponseMessage {
  message: string;
  processed: boolean;
}

const defaultResponse: ResponseMessage = { message: '', processed: false };

@Component({
  selector: 'zbp-branded-forgot-password',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, PreloaderModule, SharedModule, ButtonComponent],
  templateUrl: './branded-forgot-password.component.html',
  styleUrls: ['./branded-forgot-password.component.scss'],
})
export class BrandedForgotPasswordComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  error: ResponseMessage = defaultResponse;
  success: ResponseMessage = defaultResponse;
  formSubmitted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loginBrand: CompanyCode = null;
  isHighlights: boolean = false;
  brandTitleText: string = '';
  containerClass: string = '';
  logoAltText: string = '';

  constructor(
    private authService: AuthenticationService,
    private titleService: Title,
    private toastr: ToastrService,
    private router: Router,
    private appConfigService: AppConfigService,
  ) {}

  resetForm: FormGroup;

  ngOnInit() {
    this.setupBrandData();

    this.titleService.setTitle(`Forgot Password | ${this.brandTitleText}`);

    this.subscriptions.push(
      this.handleSubmitForm$.subscribe(),
    );

    this.resetForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }

  private setupBrandData() {
    this.loginBrand = this.appConfigService.loginBrand;

    if (this.loginBrand === CompanyCode.HighlightsPortal) {
      this.containerClass = 'hl-portal-forgot-password-container';
      this.brandTitleText  = 'Highlights Portal';
      this.logoAltText  = 'Highlights Portal logo';
      this.isHighlights = true;
    } else {
      this.containerClass = 'zb-portal-forgot-password-container';
      this.brandTitleText  = 'My ZB Portal';
      this.logoAltText  = 'My ZB Portal logo';
      this.isHighlights = false;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private clearMessages(): void {
    this.error = defaultResponse;
    this.success = defaultResponse;
  }

  showForm(): void {
    this.formSubmitted.next(false);
    this.resetForm.controls.email.setValue('');
    this.clearMessages();
  }

  cancelResetPasswordMode(): void {
    this.router.navigateByUrl('/login/user');
  }

  get email() {
    return this.resetForm.get('email');
  }

  triggerSubmitForm$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  handleSubmitForm$: Observable<any> = this.triggerSubmitForm$.pipe(
    filter(triggerSubmitForm => triggerSubmitForm),
    map(() => {
      if (!this.resetForm.valid) {
        return null;
      }
      this.formSubmitted.next(true);
      const { email } = this.resetForm.value;
      this.authService.resetPassword(email).subscribe((res) => {
        if (res.success) {
          this.success = {
            message: `<p class='reset-link-confirmation'>A password reset link has been sent to: </p><p class='reset-link-confirmation'>${email}</p>`,
            processed: true,
          };
        } else {
          const [error] = res.messages;
          this.error = { message: error, processed: true };
          this.toastr.error(error);
        }
      });
      return null;
    })
  );
}
