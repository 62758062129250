<nav class="loginHeader fixed-top bg-primary" aria-hidden="true">&nbsp;</nav>
<div class="d-flex flex-wrap align-items-center justify-content-start loginContainer w-100" [ngClass]="{'loginContainerIE': browserService.isIE }">
  <div class="backToZanerBloserContainer align-self-start">
    <div id="backToZanerBloserLinkContainer" class="text-bold ms-4 mt-2 mvBoli">
      <span class="d-block loginText">back to</span>
      <a id="backToZanerBloserLink" class="d-block ps-1 btn-link" [attr.href]="backToZanerBloserLink">zaner-bloser.com</a>
    </div>
  </div>
  <div class="loginFormContainerWrapper text-center">
    <div class="loginFormContainer mx-auto">
      <img id="primaryLogo" src="assets/img/logo.svg" alt="My ZB Portal" class="mx-auto d-block loginImage">
      <h1 class="subLogo mvBoli">for {{ subTitle }}</h1>
      <div *ngIf="error" id="error" class="alert alert-danger mx-auto" [innerHTML]="error"></div>
      <div *ngIf="success" id="success" class="alert alert-success mx-auto" [innerHTML]="success"></div>
      <form id="teacherLoginForm" (ngSubmit)="onSubmit()" #Login="ngForm" class="loginForm text-center">
        <div class="mb-3">
          <input aria-label="e-mail address" placeholder="e-mail address" type="text" class="form-control" id="email" data-cy="teacher-login-email" required [(ngModel)]="email" name="email" #emailV="ngModel" [class.is-invalid]="!emailV.valid && emailV.touched" />
        </div>
        <div class="mb-3 text-end">
          <input aria-label="password" placeholder="password" type="password" class="form-control" id="password" data-cy="teacher-login-password" required [(ngModel)]="password" name="password" #passwordV="ngModel" autocomplete="off" [class.is-invalid]="!passwordV.valid && passwordV.touched" />
          <div id="links">
            <a id="resetModeLink" class="text-primary btn-link" routerLink="/login/forgot-password">Forgot your password?</a>
          </div>
        </div>
        <div>
          <button id="loginSubmit" [disabled]="loginProcessing" type="submit" data-cy="login-submit-button" class="btn btn-outline-tertiary text-uppercase btn-sm">Log In</button>
          <i *ngIf="loginProcessing" class="fa fa-spin fa-circle-notch" aria-label="Logging in" id="loginIndicator"></i>
        </div>
        </form>
      <zbp-login-sso></zbp-login-sso>
    </div>
  </div>
  <div class="loginOperationsContainer">
    <a *ngIf="type === types.Parents" id="switchToTeacher" data-cy="switch-teacher-link" class="align-self-end btn-link" routerLink="/login/user">
      <span class="fw-bold">I'm a teacher/admin!</span> (switch to the teacher portal)
    </a>
    <a id="switchToStudent" class="align-self-end btn-link" routerLink="/login/student">
      <span class="fw-bold">I'm a student!</span> (switch to the student portal)
    </a>
    <a *ngIf="type === types.Default" id="switchToParent" class="align-self-end btn-link" routerLink="/login/parent">
      <span class="fw-bold">I'm a parent!</span> (switch to the parent portal)
    </a>
  </div>
  <div class="loginSwirlContainer mt-auto ms-auto align-self-end text-end">
    <img id="swirlImage" class="loginImage loginImageSwirl" src="assets/img/zb_greyswirl_right.png" role="presentation" alt="">
  </div>
</div>
<zbp-footer></zbp-footer>
