import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Params, RouterModule } from '@angular/router';
import { AppConfigService } from '@core/appconfig.service';
import { MasqueradeService } from '@core/services/masquerade/masquerade.service';
import { UserService } from '@core/user.service';
import { PrimaryMenu } from '@models/primary-menu';
import { IProductLine } from '@models/product-line';
import { IProductLineVariant } from '@models/product-variant';
import { IUser } from '@models/user';
import { NgbModal, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '@shared/button/button.component';
import { CompanyCode } from '@shared/enums/company-code';
import { RoleType } from '@shared/enums/role-type';
import { Helpers } from '@shared/helpers';
import { zbpIconNames } from '@shared/zbp-icon/zbp-icon-names';
import {
  Observable, Subscription, combineLatest,
  distinctUntilChanged, map,
} from 'rxjs';
import { HeaderService } from '../header-service';
import {
  districtHLAdminMainMenus,
  DISTRICT_ADMIN_MANAGE_MENU_ID,
  districtZBAdminMainMenus,
} from './menu-items/district-admin-menu';
import {
  schoolHLAdminMainMenus, SCHOOL_ADMIN_MANAGE_MENU_ID, schoolZBAdminMainMenus
} from './menu-items/school-admin-menu';
import { teacherMainMenus, TEACHERS_PRODUCTS_MENU_ID } from './menu-items/teacher-menu';

@Component({
  standalone: true,
  selector: 'zbp-branded-header',
  templateUrl: './branded-header.component.html',
  styleUrls: ['./branded-header.component.scss'],
  imports: [TranslateModule, RouterModule, CommonModule, ButtonComponent, NgbTooltipModule],
})
export class BrandedHeaderComponent implements OnDestroy, OnInit {
  menus: PrimaryMenu[] = this.headerService.currentMenus ?? [];
  dropdownMenus: PrimaryMenu[] = [];
  products: IProductLine[] = this.headerService.currentMenuProducts;
  user: IUser = null;
  user$ = this.userService.user$;
  isMasqueraded: boolean = false;
  isHighlightsPortalUser: boolean = false;
  brandLogoData = null;
  brand: string = CompanyCode.HighlightsPortal.toLowerCase();
  queryParams$: Observable<Params> = this.route.queryParams;
  isTeacher$: Observable<boolean> = this.user$.pipe(map(user => user.isTeacher));
  isSchoolAdmin$: Observable<boolean> = this.user$.pipe(map(user => user.isSchoolAdmin));
  isDistrictAdmin$: Observable<boolean> = this.user$.pipe(map(user => user.isDistrictAdmin));
  isElevatedAdmin$: Observable<boolean> = this.user$.pipe(map(user => user.isElevatedAdmin));
  teacherProductsMenuId = TEACHERS_PRODUCTS_MENU_ID;
  districtAdminManageMenuId = DISTRICT_ADMIN_MANAGE_MENU_ID;
  schoolAdminManageMenuId = SCHOOL_ADMIN_MANAGE_MENU_ID;
  iconNames = zbpIconNames;

  private subscriptions: Subscription[] = [];
  private expandedMenuItemId: string = null;

  private headerLogoDataByBrand = {
    [CompanyCode.HighlightsPortal]: {
      src: 'assets/img/highlights.svg',
      alt: 'Highlights Portal logo',
    },
    [CompanyCode.ZBPortal]: {
      src: 'assets/img/zb-icon-white.svg',
      alt: 'ZB Portal logo',
    },
  };

  buildHeaderMenu$: Observable<PrimaryMenu[]> = combineLatest([
    this.isDistrictAdmin$,
    this.isSchoolAdmin$,
    this.isTeacher$,
    this.isElevatedAdmin$,
    this.headerService.refreshHeader$,
  ])
    .pipe(
      map(([isDistrictAdmin, isSchoolAdmin, isTeacher, isElevatedAdmin]) => {
        if (this.headerService.displayPrimaryHeaderMenus) {
          if (isDistrictAdmin) {
            if (this.isHighlightsPortalUser) {
              this.menus = districtHLAdminMainMenus;
            } else {
              this.menus = districtZBAdminMainMenus;
            }
            this.dropdownMenus = this.headerService.getDropdownMenu(RoleType.DistrictAdministrator);
          } else if (isSchoolAdmin) {
            if (this.isHighlightsPortalUser) {
              this.menus = schoolHLAdminMainMenus;
            } else {
              this.menus = schoolZBAdminMainMenus;
            }
            this.dropdownMenus = this.headerService.getDropdownMenu(RoleType.SchoolAdministrator);
          } else if (isTeacher) {
            this.menus = teacherMainMenus;
          } else if (isElevatedAdmin) {
            // All Elevated Admin menu items are built by the AdminComponent directly, then added to the header service
            this.menus = this.headerService.currentMenus;
          }

          const sortedMenus = Helpers.sortByWeight(this.menus);
          this.headerService.currentMenus = sortedMenus;
          this.menus = sortedMenus;

          return sortedMenus;
        }

        this.headerService.currentMenus = [];
        this.menus = [];
        this.products = [];

        return [];
      }),
      distinctUntilChanged(),
    );

  get userNameSchoolId(): string {
    const value = this.user.getDisplayName;

    if (this.user.abbreviatedSchoolId) {
      return `${value} (${this.user.abbreviatedSchoolId})`;
    }
    return value;
  }

  get canSelfAssignLicenses(): boolean {
    return this.user.isTeacher
      && this.user.profileDetail
      && this.user.profileDetail.canManageLicenses;
  }

  primaryMenuItemClicked(event: Event, primaryHeaderMenu: HTMLElement) {
    event.preventDefault();
    event.stopPropagation();

    // reset the border to be rounded on all corners
    this.renderer.removeClass(primaryHeaderMenu, 'submenu-expanded');
    this.expandedMenuItemId = null;
  }

  chooseRoleClick() {
    this.headerService.chooseRoleClick();
  }

  /**
   * Handles expanding and collapsing dropdown menu items.
   */
  expandSubmenu(event: Event, primaryHeaderMenu: HTMLElement, dropdownItem: HTMLElement) {
    event.preventDefault();
    event.stopPropagation();

    let handleSubmenu = true;

    if (event.type === 'keydown' && event['key'] !== 'Enter') {
      handleSubmenu = false;
    }

    if (handleSubmenu) {
      const isMenuItemAlreadyExpanded = this.expandedMenuItemId === dropdownItem.id;

      // Reset all previous styling for the menu and submenus
      this.removeActiveMenuStyling(primaryHeaderMenu, dropdownItem);

      // If the menu item that was clicked isn't already expanded, then open the submenu
      if (!isMenuItemAlreadyExpanded) {
        this.expandedMenuItemId = dropdownItem.id;
        // removes rounded corners on right side of products-menu dropdown
        this.renderer.addClass(primaryHeaderMenu, 'submenu-expanded');
        this.renderer.addClass(dropdownItem, 'show');
        this.renderer.addClass(dropdownItem.firstElementChild, 'show');
      }
    }
  }

  isSingleAdmin(menu) {
    return menu.id === this.districtAdminManageMenuId
      || (menu.id === this.schoolAdminManageMenuId && this.headerService.schools.length <= 1);
  }

  isMultiAdmin(menu) {
    return menu.id === this.schoolAdminManageMenuId && this.headerService.schools.length > 1;
  }

  private removeActiveMenuStyling(primaryHeaderMenu: HTMLElement, submenuItem: HTMLElement) {
    this.expandedMenuItemId = null;
    // eslint-disable-next-line no-param-reassign
    primaryHeaderMenu.ariaExpanded = 'false';
    this.renderer.removeClass(submenuItem.firstElementChild, 'show');
    this.renderer.removeClass(primaryHeaderMenu, 'submenu-expanded');

    primaryHeaderMenu.querySelectorAll('.has-submenu').forEach((menu) => {
      // eslint-disable-next-line no-param-reassign
      menu.ariaExpanded = 'false';
      this.renderer.removeClass(menu, 'show');
      this.renderer.removeClass(menu.firstElementChild, 'show');
    });
  }

  /**
   * Closes primary menu dropdown and removes all styling from any open products/submenus
   * Clicking outside of menu will automatically close menu & items
   * Clicking on the link for the current page requires this to be reset manually
   */
  private closeMenu(primaryHeaderMenu: HTMLElement, navigationDropdownMenu: HTMLElement) {
    this.expandedMenuItemId = null;
    this.renderer.removeClass(primaryHeaderMenu, 'show');
    this.renderer.removeClass(primaryHeaderMenu, 'submenu-expanded');
    this.renderer.removeClass(navigationDropdownMenu, 'show');
    if (navigationDropdownMenu.firstElementChild) {
      this.renderer.removeClass(navigationDropdownMenu.firstElementChild, 'show');
    }

    // eslint-disable-next-line no-param-reassign
    primaryHeaderMenu.ariaExpanded = 'false';
  }

  clickedDropdownSubMenuItem(
    event: Event,
    product: IProductLine,
    variant: IProductLineVariant,
    primaryHeaderMenu: HTMLElement,
    navigationDropdownMenu: HTMLElement,
  ) {
    event.preventDefault();
    event.stopPropagation();

    let handleSubmenu = true;

    if (event.type === 'keydown' && event['key'] !== 'Enter') {
      handleSubmenu = false;
    }

    if (handleSubmenu) {
      this.closeMenu(primaryHeaderMenu, navigationDropdownMenu);
      this.headerService.navigateToProduct(product, variant, this.user.isTeacher);
    }
  }

  goHome(event: MouseEvent): boolean {
    event.preventDefault();

    if (this.headerService.logoClickedFunction) {
      this.headerService.logoClickedFunction();
    }
    return false;
  }

  handleManageNavigation(event: Event, adminManageOption: PrimaryMenu, primaryHeaderMenu: HTMLElement, navigationDropdownMenu: HTMLElement) {
    if (!adminManageOption.disabled) {
      this.closeMenu(primaryHeaderMenu, navigationDropdownMenu);
      // Only run onClickFunction if not isRouted (this is matching existing ZB functionality)
      if (adminManageOption.isRouted) {
        this.headerService.navigateToManageMenuDropdownItem(event, adminManageOption);
      } else {
        adminManageOption.onClickFunction();
      }
    }
  }

  verifyStopMasquerade() {
    const stopMasqueradeModal = this.masqueradeService.confirmStopMasqueradeModal().subscribe();

    this.subscriptions.push(stopMasqueradeModal);
  }

  constructor(
    private appConfig: AppConfigService,
    private userService: UserService,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    public headerService: HeaderService,
    private masqueradeService: MasqueradeService,
    public modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    this.brand = this.appConfig.loginBrand;
    this.brandLogoData = this.headerLogoDataByBrand[this.brand];
    this.isHighlightsPortalUser = this.brand === CompanyCode.HighlightsPortal;
    this.user = this.userService.user;
    this.isMasqueraded = this.userService.isMasqueraded;

    this.subscriptions.push(
      this.buildHeaderMenu$.subscribe(),
    );

    this.headerService.refreshHeader$.next(true);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
