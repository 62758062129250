import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { JwtModule, JwtModuleOptions, JWT_OPTIONS } from '@auth0/angular-jwt';
import { throwIfAlreadyLoaded } from '@core/module-import-guard';
import { ApiInterceptor } from '@interceptors/api.interceptor';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { RoleType } from '@shared/enums/role-type';
import { Angulartics2Module, Angulartics2GoogleTagManager } from 'angulartics2';

import { environment } from '../../environments/environment';
import { AgreementService } from '../agreement/agreement.service';

import { AnalyticsService } from './analytics.service';
import { AppConfigService } from './appconfig.service';
import { AuthenticationService } from './authentication.service';
import { AvatarService } from './avatar.service';
import { BrowserService } from './browser.service';
import { CacheService } from './cache.service';
import { DefaultTranslationProvider } from './default-translation.provider';
import { FileReaderService } from './file-reader.service';
import { ErrorInterceptor } from './interceptors/error-interceptor';
import { SchoolIntegrationIdInterceptor } from './interceptors/school-integration-id-interceptor';
import { ProductService } from './product.service';
import { QuestService } from './quest.service';
import { ReportsService } from './reports.service';
import { AnnouncementApiService } from './services/announcement-notifications/announcement/announcement-api.service';
import { AgreementApiService } from './services/application/agreement/agreement-api.service';
import { CountryApiService } from './services/application/country/country-api.service';
import { EmailApiService } from './services/application/email/email-api.service';
import { EducationalUnitApiService } from './services/educational-unit/educational-unit-api.service';
import { OrdersApiService } from './services/order/orders-api.service';
import { AdminProductsApiService } from './services/product/admin-products-api.service';
import { StudentDashboardReturnService } from './services/student/student-dashboard-return.service';
import { UserApiService } from './services/user/user-api.service';
import { SfxService } from './sfx.service';
import { metaReducers, CORE_REDUCERS } from './store';
import { CoreFacade } from './store/facade';
import { StudentQuestService } from './student-quest.service';
import { TranslateApiLoader } from './translate-api.loader';
import { UserMessageService } from './user-message.service';
import { UserService } from './user.service';

/** Provides a factory for JWT interceptor configuration so that whitelist domains can be configured dynamically. */
export function jwtOptionsFactory(cache: CacheService) {
  return {
    tokenGetter: () => {
      const cached = cache.coreApiToken;
      if (cached) {
        return cached;
      }
      return null;
    },
    disallowedRoutes: [
      /^sites/,
    ],
    allowedDomains: [environment.apiUrl.replace('https://', ''), environment.proxyApiUrl.replace('https://', '')],
  };
}

export function translateHttpLoaderFactory(
  http: HttpClient,
  appConfig: AppConfigService,
  role: RoleType = null): TranslateLoader {
  return new TranslateApiLoader(http, appConfig, role);
}

/**
 * The Core module is the home for singleton services whose instances will be
 * shared throughout the application in the CoreModule.
 *
 * See: https://angular.io/docs/ts/latest/guide/style-guide.html#04-11
 *
 * DO NOT IMPORT the CoreModule anywhere except in the AppModule
 */
@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'CSRF-Token',
      headerName: 'X-CSRF-Token',
    }),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [CacheService],
      },
    } as JwtModuleOptions),
    Angulartics2Module.forRoot(),
    TranslateModule.forRoot(),
    StoreModule.forRoot(CORE_REDUCERS, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      }
    }),
    environment.environment !== 'prod' ? StoreDevtoolsModule.instrument({ connectInZone: true }) : [],
    EffectsModule.forRoot([]),
  ],
  declarations: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SchoolIntegrationIdInterceptor,
      multi: true,
    },
    AdminProductsApiService,
    AgreementService,
    AgreementApiService,
    AnalyticsService,
    Angulartics2GoogleTagManager,
    AnnouncementApiService,
    AuthenticationService,
    AvatarService,
    BrowserService,
    CacheService,
    CountryApiService,
    CoreFacade,
    DefaultTranslationProvider,
    EducationalUnitApiService,
    EmailApiService,
    FileReaderService,
    HttpClientModule,
    OrdersApiService,
    ProductService,
    QuestService,
    ReportsService,
    SfxService,
    StudentQuestService,
    TranslateApiLoader,
    UserMessageService,
    UserService,
    UserApiService,
    StudentDashboardReturnService,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
